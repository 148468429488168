<div [innerHtml]="faqLD"></div>
<div class="bring-phone-page">
    <section class="banner-section">
        <div class="banner-image-container">
            <div class="banner-img">
                <picture>
                    <source srcset='assets/img/banners/byod-banner-mobile.webp' media="(max-width: 639.9px)"
                        type="image/webp">
                    <source srcset='assets/img/banners/byod-banner-mobile.png' media="(max-width: 639.9px)"
                        type="image/png">
                    <source srcset='assets/img/banners/byod-banner-tablet.webp' media="(max-width: 1024.9px)"
                        type="image/webp">
                    <source srcset='assets/img/banners/byod-banner-tablet.png' media="(max-width: 1024.9px)"
                        type="image/png">
                    <source srcset='assets/img/banners/byod-banner-desktop.webp' media="(min-width: 1025px)"
                        type="image/webp">
                    <source srcset='assets/img/banners/byod-banner-desktop.png' media="(min-width: 1025px)"
                        type="image/png">
                    <img src="assets/img/banners/byod-banner-desktop.png" alt="good2go byod banner image">
                </picture>
            </div>
        </div>
        <div class="banner-text-content">
            <h1 class="header">Love Your Phone?</h1>
            <p class="sub-header">Bring it to Good2Go Mobile and start saving.</p>
            <div class="get-started-button">
                <button class="button primary" data-cy="getStartedBtn"
                    [routerLink]="[ACTIVATION_ROUTE_URLS.BASE + '/' + ACTIVATION_ROUTE_URLS.CHECK_PHONE]">Get
                    started</button>
            </div>
        </div>
    </section>
    <section class="bring-phone-steps-section">
        <h2 class="title">Bring your own phone to Good2Go Mobile</h2>
        <div class="all-steps">
            <div class="step-part">
                <div class="icon">
                    <img src="assets/icon/keep-device-step.svg" alt="keep device icon">
                </div>
                <p class="step-title">Keep the device you already love</p>
                <p class="step-details">Let’s find out if your phone is compatible with our network.</p>
                <div class="step-button">
                    <button class="primary button"
                        [routerLink]="[ACTIVATION_ROUTE_URLS.BASE + '/' + ACTIVATION_ROUTE_URLS.CHECK_PHONE]">Check
                        phone</button>
                </div>
            </div>
            <div class="step-part">
                <div class="icon">
                    <img src="assets/icon/choose-plan-step.svg" alt="choose plan icon">
                </div>
                <p class="step-title">Choose the Right Plan for You</p>
                <p class="step-details">Get a FREE SIM Kit when you sign up to a Good2Go Mobile plan online.</p>
                <div class="step-button">
                    <button class="primary button"
                        [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.PLANS_AND_FEATURES + '/' + PLANS_SHOP_ROUTE_URLS.NEW_PLAN]">Shop
                        plans</button>
                </div>
            </div>
            <div class="step-part bottom-0">
                <div class="icon">
                    <img src="assets/icon/activate-step.svg" alt="activate icon">
                </div>
                <p class="step-title">Activate</p>
                <p class="step-details">Keep your existing phone number or request a new one.</p>
            </div>
        </div>
    </section>
    <section class="bring-phone-content-section">
        <div class="side-section margin-bottom">
            <div class="image-part">
                <picture>
                    <source srcset='assets/img/two-girls-with-phone-mobile.webp' media="(max-width: 639.9px)"
                        type="image/webp">
                    <source srcset='assets/img/two-girls-with-phone-mobile.png' media="(max-width: 639.9px)"
                        type="image/png">
                    <source srcset='assets/img/two-girls-with-phone-tablet.webp' media="(max-width: 1024.9px)"
                        type="image/webp">
                    <source srcset='assets/img/two-girls-with-phone-tablet.png' media="(max-width: 1024.9px)"
                        type="image/png">
                    <source srcset='assets/img/two-girls-with-phone-desktop.webp' media="(min-width: 1025px)"
                        type="image/webp">
                    <source srcset='assets/img/two-girls-with-phone-desktop.png' media="(min-width: 1025px)"
                        type="image/png">
                    <img src="assets/img/two-girls-with-phone-desktop.png" alt="two-girls-with-phone-image">
                </picture>
            </div>
            <div class="details-part">
                <h2>Get $10 for every friend you invite</h2>
                <p>Friends who are new to Good2Go <b>get $10</b> and you
                    <b>get $10 after they activate</b> their first purchase.
                </p>
                <div class="features-section">
                    <div class="feature">
                        <img src="assets/img/invite.svg" alt="Invite friends to Good2Go Mobile" width="64"
                            height="64" />
                        <p class="smallest refer-note"><b>Invite friends to Good2Go Mobile</b></p>
                    </div>
                    <div class="feature">
                        <img src="assets/img/get-discount.svg" alt="They get a discount" width="64" height="64" />
                        <p class="smallest"><b>They get a discount</b></p>
                    </div>
                    <div class="feature">
                        <img src="assets/img/manage-referrals.svg" alt="Manage your referrals on the go!" width="64"
                            height="64" />
                        <p class="smallest refer-note"><b>Manage your referrals on the go!</b></p>
                    </div>
                </div>
                <div class="action">
                    <button class="button primary" aria-label="Invite Friends!"
                        [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.REFER_FRIEND]">Invite
                        Friends!</button>
                </div>
            </div>
        </div>
    </section>
    <section class="check-phone-section">
        <h2 class="title page-section">Check your phone’s compatibility with the Good2Go network</h2>
        <p class="description page-section">Most 4G LTE/ 5G phones work on our network. Let’s confirm if yours is compatible.
        </p>
        <button class="button primary"
            [routerLink]="ACTIVATION_ROUTE_URLS.BASE + '/' + ACTIVATION_ROUTE_URLS.CHECK_PHONE"
            data-cy="checkYourPhoneBtn">Check your
            phone</button>
    </section>
    <section class="faqs-section">
        <h2 class="title">Got Questions? We’re here to answer</h2>
        <div class="bring-phone-questions">
            <div class="row">
                <div class="twelve questions">
                    <div *ngFor="let qst of firstThreeQuestions">
                        <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                            [isActive]="qst?.fields?.questionId == questionIdParam"
                            (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                            <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                        </app-question-answer>
                    </div>
                    <div *ngIf="!!showMore">
                        <div *ngFor="let qst of restQuestions">
                            <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                                [isActive]="qst?.fields?.questionId == questionIdParam"
                                (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                                <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                            </app-question-answer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="more-or-less-faqs" *ngIf="!showMore"><a (click)="showMore = true">More FAQs</a></p>
        <p class="more-or-less-faqs" *ngIf="!!showMore"><a (click)="showMore = false; questionId = 'q0'">Less FAQs</a>
        </p>
        <div class="bring-phone-info">
            <p class="footer">Most unlocked 4G LTE/ 5G phones will work on the Good2Go network, however, we don’t have control
                over the
                availability of the features we provide that are not available from your unlocked phone. Unfortunately,
                some features like Wi-Fi calling, VoLTE, MMS may not work on our network. Good2Go Mobile cannot and does
                not guarantee the functionality or performance of any handsets or devices for use on the Good2Go Mobile
                network. Nor do we guarantee the functionality of any handsets or devices. As a courtesy, we provide
                Internet and picture messaging (MMS) settings for some devices. Good2Go Mobile cannot guarantee the
                effectiveness of these settings, nor their placement in the device and therefore does not assume any
                liability for the lack of effectiveness of these settings for the devices</p>
            <p class="footer">Wi-Fi Calling requires Wi-Fi Calling capable smartphone and Wi-Fi network. Once enabled,
                phone
                automatically makes/receives calls/text messages over Wi-Fi in the U.S. If wireless network coverage is
                weak or unavailable, or outside the Domestic Coverage Area anytime you connect to a Wi-Fi network. Wi-Fi
                Calling is available on select devices. Use of Wi-Fi Calling may be restricted in some countries. If you
                move in or out of Wi-Fi coverage while on a Wi-Fi call, your call will disconnect unless you have AT&T
                HD Voice coverage (911 calls will disconnect even if you are within HD Voice coverage). HD Voice not
                available everywhere. To enable Wi-Fi Calling, you must have a wireless account provisioned for HD
                Voice. Cannot use Wi-Fi Calling to call 211, 311, 511, and 811. <a
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.FAQS + '/wifi-calling']">Learn
                    more</a>.</p>
        </div>
    </section>
</div>