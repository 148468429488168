<div [innerHtml]="faqLD"></div>
<div class="hd-voice-page">
    <section class="banner-section">
        <div class="banner-image-container">
            <div class="banner-img">
                <picture>
                    <source srcset='assets/img/banners/hd-voice-banner-mobile.webp' media="(max-width: 639.9px)"
                        type="image/webp">
                    <source srcset='assets/img/banners/hd-voice-banner-mobile.png' media="(max-width: 639.9px)"
                        type="image/png">
                    <source srcset='assets/img/banners/hd-voice-banner-tablet.webp' media="(max-width: 1024.9px)"
                        type="image/webp">
                    <source srcset='assets/img/banners/hd-voice-banner-tablet.png' media="(max-width: 1024.9px)"
                        type="image/png">
                    <source srcset='assets/img/banners/hd-voice-banner-desktop.webp' media="(min-width: 1025px)"
                        type="image/webp">
                    <source srcset='assets/img/banners/hd-voice-banner-desktop.png' media="(min-width: 1025px)"
                        type="image/png">
                    <img src="assets/img/banners/hd-voice-banner-desktop.png" alt="good2go hd voice banner image">
                </picture>
            </div>
        </div>
        <div class="banner-text-content">
            <h1 class="header">HD Voice</h1>
            <p class="sub-header">A new dimension in voice quality for Good2Go Mobile customers</p>
        </div>
    </section>
    <section class="second-section">
        <div class="img-desc-part bottom">
            <div class="image-part">
                <picture>
                    <source srcset='assets/img/american-man-using-hd-voice-mobile.webp' media="(max-width: 639.9px)"
                        type="image/webp">
                    <source srcset='assets/img/american-man-using-hd-voice-mobile.png' media="(max-width: 639.9px)"
                        type="image/png">
                    <source srcset='assets/img/american-man-using-hd-voice.webp' media="(min-width: 640px)"
                        type="image/webp">
                    <source srcset='assets/img/american-man-using-hd-voice.png' media="(min-width: 640px)"
                        type="image/png">
                    <img src="assets/img/american-man-using-hd-voice.png"
                        alt="Man on Good2Go Mobile phone uses HD Voice to hear in a room full of people">
                </picture>
            </div>
            <div class="description-part">
                <h2>Get clear audio on calls</h2>
                <p class="step-description">HD Voice is a wideband audio technology that helps make calls sound much
                    crisper and allows you to use multiple features on your phone at the same time. By extending the
                    frequency range of audio signals, HD Voice does two things well:</p>
            </div>
        </div>
        <div class="desc-img-part bottom">
            <div class="description-part">
                <h2>Multitask with VoLTE</h2>
                <p class="step-description">HD Voice allows you to do it all once on your phone: Talk, browse, post,
                    message, use apps and stream at 4G LTE speeds.</p>
            </div>
            <div class="image-part">
                <picture>
                    <source srcset='assets/img/man-talks-and-surfs-the-web-mobile.webp' media="(max-width: 639.9px)"
                        type="image/webp">
                    <source srcset='assets/img/man-talks-and-surfs-the-web-mobile.png' media="(max-width: 639.9px)"
                        type="image/png">
                    <source srcset='assets/img/man-talks-and-surfs-the-web.webp' media="(min-width: 640px)"
                        type="image/webp">
                    <source srcset='assets/img/man-talks-and-surfs-the-web.png' media="(min-width: 640px)"
                        type="image/png">
                    <img src="assets/img/man-talks-and-surfs-the-web.png"
                        alt="man uses HD voice to talk and surf the web on phone at the same time">
                </picture>
            </div>
        </div>
        <div class="img-desc-part">
            <div class="image-part">
                <picture>
                    <source srcset='assets/img/woman-using-hd-voice-mobile.webp' media="(max-width: 639.9px)"
                        type="image/webp">
                    <source srcset='assets/img/woman-using-hd-voice-mobile.png' media="(max-width: 639.9px)"
                        type="image/png">
                    <source srcset='assets/img/woman-using-hd-voice.webp' media="(min-width: 640px)" type="image/webp">
                    <source srcset='assets/img/woman-using-hd-voice.png' media="(min-width: 640px)" type="image/png">
                    <img src="assets/img/woman-using-hd-voice.png"
                        alt="Woman using HD Voice and VoLTE to hear better on smartphone">
                </picture>
            </div>
            <div class="description-part">
                <h2>How does HD Voice work?</h2>
                <p class="step-description">HD Voice, which is powered by VoLTE (voice over LTE), uses wide-band audio
                    technology and noise cancellation. That means voices sound more natural, and you’ll hear much less
                    background noise compared to standard voice calls</p>
            </div>
        </div>
    </section>
    <section class="faqs-section">
        <h2 class="title">Frequently Asked Questions</h2>
        <div class="hd-voice-questions">
            <div class="row">
                <div class="twelve questions">
                    <div *ngFor="let qst of firstThreeQuestions">
                        <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                            [isActive]="qst?.fields?.questionId === questionIdParam"
                            (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                            <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                        </app-question-answer>
                    </div>
                    <div *ngIf="!!showMore">
                        <div *ngFor="let qst of restQuestions">
                            <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                                [isActive]="qst?.fields?.questionId === questionIdParam"
                                (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                                <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                            </app-question-answer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="more-or-less-faqs" *ngIf="!showMore"><a (click)="showMore = true">More FAQs</a></p>
        <p class="more-or-less-faqs" *ngIf="!!showMore"><a (click)="showMore = false; questionId = 'q0'">Less FAQs</a>
        </p>
        <div class="hd-voice-info">
            <p class="footer">Most unlocked GSM phones will work on the Good2Go network, however, we don’t have control over the
                availability of features on your unlocked phone. Unfortunately, some features like Wi-Fi Calling, HD
                Voice and VoLTE as well as MMS may not work on our network. Good2Go Mobile cannot and does not guarantee
                the functionality and performance of devices used on the Good2Go Mobile network. Nor do we guarantee the
                functionality of any devices. As a courtesy, we provide Internet and picture messaging (MMS) settings
                for some devices. Good2Go Mobile cannot guarantee the effectiveness of these settings, nor their
                placement in the device, and therefore does not assume any liability for the lack of effectiveness of
                these devices.</p>
            <p class="footer">Wi-Fi Calling requires a Wi-Fi Calling capable smartphone and access to a Wi-Fi network. Once enable,
                your phone automatically makes/receives calls/text messages over WiFi if in the U.S. If wireless network
                coverage is weak or unavailable, or outside the Domestic Coverage Area anytime you connect to a Wi-Fi
                network. Wi-Fi Calling is available on select devices. Use of Wi-Fi Calling may be restricted in some
                countries. If you move in or out of Wi-Fi coverage while on a Wi-Fi call, your call will disconnect
                unless you have HD Voice coverage. (911 calls will disconnect even if you are within HD Voice coverage).
                HD Voice is not available everywhere. To enable Wi-Fi Calling, you must have a wireless account
                provisioned for HD Voice. You cannot use Wi-Fi Calling to call 211, 311, 511, and 811. <a
                    [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.FAQS + '/wifi-calling']">Learn
                    more</a>.</p>
        </div>
    </section>
</div>