<div class="modal">
<div class="container model-container {{context.customClass}}">
  <div class="modal-header">
    <h4 class="modal-heading">Swap to eSIM</h4>
    <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
  </div>
  <div class="modal-body">
    <div class="content">
      <p>You are about to swap to eSIM <b>({{context.iccid}})</b>  on Phone Number <b>{{context.mdn}}</b></p>
      <button class="button primary" (click)="confirm()" aria-label="proceed">Proceed</button>
    </div>
  </div>
</div>
</div>
