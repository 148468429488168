import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-instractions-five-g-ios17-modal',
  templateUrl: './instractions-five-g-ios17-modal.component.html',
  styleUrls: ['./instractions-five-g-ios17-modal.component.scss']
})
export class InstractionsFiveGIos17ModalComponent implements OnInit {

  constructor(private dialog: MatDialogRef<InstractionsFiveGIos17ModalComponent>) {
    this.activeStep = 1;
  }

  activeStep: number;

  ngOnInit(): void {
  }

  next() {
    if (this.activeStep == 4)
    this.dialog.close();
    ++this.activeStep;
  }

  back() {
    if (this.activeStep == 1)
      return;
    --this.activeStep;
  }

  close() {
    this.dialog.close();
  }

}