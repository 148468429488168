<div class="modal">
    <div class="container model-container {{context.customClass}}">
      <div class="modal-header">
        <h2 class="header"> {{context.title}}</h2>
        <button *ngIf="context?.hasCloseLink" class="modal-close modal-toggle" (click)="closeDialog()"><i
          class="icon-close"></i></button>
      </div>
      <div class="modal-body">
        <div class="modal-content">
            <p class="smaller message" *ngIf="!context.customHTML">
              {{context.message}}
            </p>
            <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
        
          <div class="modal-actions">
            <button class="button primary" (click)="closeDialog('current')" id="current-button" data-cy="current-button">Keep current address</button>
            <button class="button secondary" (click)="closeDialog('verified')" id="verified-button" data-cy="verified-button">Use verified address</button>
          </div>
        </div>
      </div>
    </div>
    </div>