<div class="modal">
    <div class="container model-container {{context.customClass}}" (keypress.esc)="onKeydown($event)">
        <div class="modal-header">
            <button *ngIf="context?.hasCloseLink" class="modal-close modal-toggle" (click)="closeDialog(false)"><i
                    class="icon-close"></i></button>
            <h2 class="modal-heading">{{context?.title}}</h2>
        </div>
        <div class="modal-body">
            <p class="title">Your Activation Code is:</p>
            <p class="activation-code"> {{!!context.selectedPlan?.eSimDetails ?
                context?.selectedPlan?.eSimDetails?.activationCode : context?.activationCode }}</p>
            <p class="title">Your {{!!context?.selectedPlan?.eSimDetails ? 'e': ''}}SIM will be activated on the
                following device:</p>
            <p class="info" *ngIf="!!context?.phoneImei">IMEI: <b>{{context.phoneImei}}</b></p>
            <section class="modal-actions">
                <button class="button primary" data-cy="proceedBtn" (click)="startMigrationService()">Proceed</button>
                <button class="button secondary" data-cy="tryAgainBtn" (click)="closeDialog()">Try Again</button>
            </section>
        </div>
    </div>
</div>