import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlatformLocation } from '@angular/common';
import Swiper, { EffectFade, Navigation } from 'swiper';

export class MigrationModalContext {
  public customClass?: string;
}

@Component({
  selector: 'app-migration-steps',
  templateUrl: './migration-steps.component.html'
})
export class MigrationStepsComponent implements OnInit {
  public context: any;
  public config: any = {
    a11y: true,
    slidesPerView: 1,
    spaceBetween: 60,
    direction: 'horizontal',
    scrollbar: false,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    centeredSlides: true,
    observer: true,
    speed: 1000,
    roundLengths: true,
    autoplay: {
      delay: 8000, // 8 seconds
      disableOnInteraction: false
    },
    pagination: {
      hideOnClick: false,
      el: '.swiper-pagination',
      clickable: true
    },
  };
  public index = 0;
  public swiper;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<MigrationModalContext>, private location: PlatformLocation) {
    location.onPopState(() => { this.beforeDismiss();this.dialog.close();});
    this.context = data;

  }
  ngOnInit(): void {
   this.swiper = new Swiper('.swiper-container', {
      lazy: true,
      hashNavigation: true,
      modules: [Navigation, EffectFade],
      ...this.config
    });
  }
  public slideChanged(): void {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 60,
      direction: 'horizontal',
      scrollbar: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      centeredSlides: true,
      observer: true,
      speed: 1000,
      roundLengths: true,
      autoplay: {
        delay: 8000, // 8 seconds
        disableOnInteraction: false
      },
      pagination: {
        hideOnClick: false,
        el: '.swiper-pagination',
        clickable: true
      },
    };
  }
  public showNextStep(): void {
    this.swiper.slideNext();
  }
  public startMigration(result): void{
    this.dialog.close(result);
  }
  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

 closeDialog(): void {
  this.beforeDismiss();
    this.dialog.close();
  }
}
