<div class="modal">
  <div class="container model-container checkout-login-modal">
    <div class="modal-header">
      <h4 class="modal-heading" *ngIf="!!showSignupSection">Sign up here for faster checkout!</h4>
      <h2 class="modal-heading" *ngIf="!showSignupSection">Welcome back!</h2>
      <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
    </div>
    <div class="modal-body">
      <div class="modal-content" *ngIf="!showSignupSection">
        <h4 class="desc">Sign in to your account for faster checkout!</h4>
        <form (ngSubmit)="login()" #loginForm="ngForm" class="login-form">
          <fieldset class="form-section">
            <label for="email" class="label">Email Address</label>
            <input id="loginEmail" data-cy="loginEmail" type="email" name="loginEmail" required [(ngModel)]="email"
              autocomplete="email" #loginEmail="ngModel" [pattern]="emailPattern"
              placeholder="Enter your email address">
            <label class="validation-message alert" *ngIf="loginEmail?.errors?.required && loginEmail?.touched"
              id="required-email-msg" data-cy="required-email-msg">Email is required</label>
            <label class="validation-message alert" *ngIf="loginEmail?.errors?.pattern" id="invalid-email-msg"
              data-cy="invalidEmailMsg">
              Please enter a valid email
            </label>
          </fieldset>
          <fieldset class="form-section">
            <label for="password" class="label">Password</label>
            <input id="loginPassword" data-cy="loginPassword" type="password" name="loginPassword" required
              [(ngModel)]="password" autocomplete="current-password" class="passInput" #loginPassword="ngModel"
              placeholder="Enter your password">
            <label class="validation-message alert" *ngIf="loginPassword?.errors?.required && loginPassword?.touched"
              id="required-password-msg">Password is required</label>
            <a class="forget" (click)="goToForgotPassword()"><b>Forgot Password?</b></a>
          </fieldset>
          <div class="actions-section">
            <fieldset class="primary-actions">
              <button class="button primary" type="submit" id="sign-in-button" data-cy="sign-in-button">Sign in</button>
            </fieldset>
            <fieldset class="secondary-actions">
              <p class="secondary-text">Don’t have an account?</p>
              <a class="secondary-action" (click)="showSignUp()" id="signup-link" data-cy="signup-link"><b>Sign up for
                  an
                  account</b></a>
            </fieldset>
          </div>
        </form>
      </div>
      <div class="modal-content signup" *ngIf="!!showSignupSection">
        <fieldset class="secondary-actions mobile-center">
          <p class="secondary-text">Already have an account? </p>
          <a class="secondary-action" (click)="showSignupSection = false" id="sign-in"><b>Sign in</b></a>
        </fieldset>
        <form [formGroup]="userForm" class="sign-up-form">
          <div class="form-row">
            <fieldset class="left">
              <label for="firstName">First Name:</label>
              <input id="firstName" data-cy="firstName" type="text" formControlName="firstName" required
                autocomplete="given-name" placeholder="Enter your first name" />
              <label class="validation-message alert"
                *ngIf="userForm.controls.firstName.hasError('required') && userForm.controls.firstName.touched"
                id="required-firstName-msg" data-cy="firstNameRequiredMsg">
                First name is required </label>
              <label class="validation-message alert" *ngIf="userForm.controls.firstName.hasError('pattern')"
                id="invalid-firstName-msg" data-cy="invalidFirstNameMsg">
                First name is invalid </label>
            </fieldset>
            <fieldset class="right">
              <label for="lastName">Last Name:</label>
              <input id="lastName" data-cy="lastName" type="text" formControlName="lastName" required
                autocomplete="family-name" placeholder="Enter your last name" />
              <label class="validation-message alert"
                *ngIf="userForm.controls.lastName.hasError('required') && userForm.controls.lastName.touched"
                id="required-lastName-msg" data-cy="lastNameRequiredMsg">
                Last name is required </label>
              <label class="validation-message alert" *ngIf="userForm.controls.lastName.hasError('pattern')"
                id="invalid-lastName-msg" data-cy="invalidLastNameMsg">
                Last name is invalid </label>
            </fieldset>
          </div>
          <div class="form-section">
            <fieldset class="full">
              <label for="email">Email Address:</label>
              <input id="email" data-cy="email" data-cy="email" type="email" formControlName="email" required
                autocomplete="email" placeholder="Enter your email address" />
              <label class='validation-message alert' *ngIf="userForm.controls.email.hasError('pattern')"
                id="invalid-email-msg" data-cy="nvalidEmailMsg">
                Email address is invalid.
              </label>
              <label class='validation-message alert'
                *ngIf="userForm.controls.email.hasError('required') && userForm.controls.email.touched"
                id="required-email-msg" data-cy="required-email-msg">
                Email address is required.
              </label>
            </fieldset>
          </div>
          <div class="form-row password-fields">
            <fieldset class="left">
              <label for="password">Password:</label>
              <input id="password" data-cy="password" type="password" formControlName="password" required
                autocomplete="new-password" placeholder="Enter a strong password" />
                <img src="assets/img/grey-eye.svg" class="eye-icon" (click)="togglePasswordVisibility('password')">
                <label class='validation-message alert' *ngIf="userForm.controls.password.touched && userForm.controls.password.hasError('required')"
                id="required-password-msg">
                Password is required
              </label>
              </fieldset>

            <fieldset class="right">
              <label for="confirmPassword">Confirm Password:</label>
              <input id="confirmPassword" data-cy="confirmPassword" type="password" formControlName="confirmPassword"
                required autocomplete="new-password" placeholder="Re-enter your password" />
                <img src="assets/img/grey-eye.svg" class="eye-icon" (click)="togglePasswordVisibility('confirmPassword')">
                <label class='validation-message alert' *ngIf="userForm.controls.confirmPassword.touched && userForm.controls.confirmPassword.hasError('required') && !userForm.controls.password.value"
                id="required-confirmation-msg">
                Password confirmation is required
              </label>
            </fieldset>
          </div>
          <ng-container>
            <fieldset class="full pass" *ngIf="userForm.controls.password.touched && !userForm.controls.password.valid && !userForm.controls.password.hasError('required')">
              <label class="validation-message alert" id="password-validation-msg" data-cy="passRequireddMsg">
                Password must be between 6 and 12 characters with at least one capital letter, one small letter, and one
                digit. </label>
            </fieldset>
            <fieldset class="full pass"
              *ngIf="userForm.controls.confirmPassword.touched && userForm.hasError('mismatchedPasswords')">
              <label class="validation-message alert" id="confirmPassword-validation-msg" data-cy="mismatchedPassMsg">
                Passwords do not match </label>
            </fieldset>
          </ng-container>
          <div class="marketing-prefrences">
            <input type="checkbox" formControlName="marketingEmails">
            <label class="special-label">I want to receive latest offers and promotions from Good2Go Mobile via email.</label>
          </div>
          <div class="form-row gap">
            <fieldset class="primary-actions">
              <button type="button" class="button primary" id="verifyButton" data-cy="submitBtn"
                (click)="signUp()">Submit</button>
            </fieldset>
            <fieldset class="secondary-actions right-aligned">
              <p class="secondary-text">Already have an account? </p>
              <a class="secondary-action" (click)="showSignupSection = false" id="sign-in"><b>Sign in</b></a>
            </fieldset>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>