import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ActionsAnalyticsService, CART_TYPES, IVoucherData, MobileCustomPlansService, MobilePlanItem, PURCHASE_INTENT } from '@ztarmobile/zwp-service-backend';
import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { combineLatest, take, takeWhile } from 'rxjs/operators';
import { ContentfulService } from 'src/services/contentful.service';
import { ACCOUNT_ROUTE_URLS, ACTIVATION_ROUTE_URLS, ROUTE_URLS, SHOP_ROUTE_URLS, SUPPORT_ROUTE_URLS } from '../../app.routes.names';
import Swiper, { EffectFade, Keyboard, Navigation, Pagination, SwiperOptions } from 'swiper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CAPTCHA_BOT_ERROR_CODE, EMAIL_PATTERN } from '@app/app.config';
import { FadeInOutAnimation, SlideInOutAnimation } from '@app/app.animations';
import { InvisibleRecaptchaComponent } from '@widgets/invisible-recaptcha/invisible-recaptcha.component';
import { INVISIBLE_CAPTCHA_ID } from '@env/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ModalHelperService } from '@services/modal-helper.service';
import { NewsletterService } from '@ztarmobile/zwp-service-backend-v2';
import { AppState } from '@app/app.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-landing-acp-wind-down',
  templateUrl: './landing-acp-wind-down.component.html',
  styleUrls: ['./landing-acp-wind-down.component.scss'],
  animations: [FadeInOutAnimation, SlideInOutAnimation]
})
export class AcpLandingWindDown implements OnInit, OnDestroy {
  selectedPlan: any;
  isReplacePlan: any;

  public ROUTE_URLS = ROUTE_URLS;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;
  public ACTIVATION_ROUTE_URLS = ACTIVATION_ROUTE_URLS;
  public ACCOUNT_ROUTE_URLS = ACCOUNT_ROUTE_URLS;
  public SITE_ID = INVISIBLE_CAPTCHA_ID;

  public questionIdParam: any;
  public collapsed: boolean;
  public firstThreeQuestions;
  public restQuestions;
  public showMore = false;
  public isLoggedIn = false;
  public userEmail = '';

  public cardExpanded = [false, false, false];
  public mouseOvered = [false, true, false];
  public transitionState = ['out', 'out', 'out'];

  public addExtraHeight = false;

  public filteredPlans: Array<MobilePlanItem> = [];
  public desktopFilteredPlans: Array<MobilePlanItem> = [];
  public subscriptionPlans: MobilePlanItem[];

  public categories = [];
  public innerWidth;
  public discountPercent = 50 / 100;
  public notifyForm: FormGroup;
  public showNotifyForm = false;
  public isNotified = false;
  public action = 'g2g_acp_newsletter_subscription';
  public recaptchaResolvedSubscription: Subscription;
  
  private alive = true;
  private allBasePlans: Array<MobilePlanItem>;

  selectedCategory = { subscriptionCycles: 1, title: '1-Month' };
  selectedIndex: number;
  activePlan: boolean;
  location: any;
  planPriceParam: any;
  selectedPrice: number = 20;
  public captchaResponse: string;
  public istemporarily = false;

  plansSwiper: any;
  public plansConfig: SwiperOptions = {
    direction: 'horizontal',
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    centeredSlides: true,
    roundLengths: true,
    loopFillGroupWithBlank: true,
    slideToClickedSlide: true,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    slidesPerView: 'auto',
    initialSlide: 3,
    observer: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0
  };

  constructor(
    private contentfulService: ContentfulService, private analyticsService: ActionsAnalyticsService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public appState: AppState,
    private newsLettersService: NewsletterService,
    private mobilePlansService: MobileCustomPlansService, private router: Router, private simpleAuthService: SimpleAuthService,
    private angularFireService: AngularFireAuth,
    private modalHelper: ModalHelperService) {
    this.simpleAuthService.userState.pipe(takeWhile(() => this.alive))
      .subscribe((authState) => {
        if (!!authState) {
          this.isLoggedIn = !!authState && !authState.isAnonymous;
          this.userEmail = authState.email;
        }
      });
  }
  ngOnInit(): void {
    this.innerWidth = document.documentElement.clientWidth;
    this.initPlans();
    this.initContentful();

    this.notifyForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])]
    });

    const acpStopDate = new Date("February 7, 2024").getTime();
    const todaysDate = new Date().getTime();
    this.istemporarily = (acpStopDate >= todaysDate);
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.recaptchaResolvedSubscription) {
      this.recaptchaResolvedSubscription.unsubscribe();
    }
  }

  public toggleActive(questionId, answerId): void {
    if (this.questionIdParam === questionId && !this.collapsed) {
      this.questionIdParam = 'q0';
    } else {
      this.questionIdParam = questionId;
      this.collapsed = false;
      this.callRichText(answerId);
    }
  }

  public popCard(i, doPop) {
    if (this.innerWidth < 1025) {
      this.mouseOvered = [false, false, false];
      return;
    }

    if (!doPop) {
      this.mouseOvered = [false, true, false];
      return;
    }
    this.mouseOvered[i] = doPop;
    if (i != 1) {
      this.mouseOvered[1] = !doPop;
    }
  }

  public showDetails(index, doShow): void {
    this.cardExpanded = Array(this.cardExpanded.length).fill(false);
    this.cardExpanded[index] = doShow;
    this.transitionState[index] = this.cardExpanded[index] ? 'in' : 'out';
    this.addExtraHeight = this.cardExpanded.indexOf(true) > -1;
  }

  public openForm() {
    if (this.isLoggedIn) {
      this.appState.resetAndExecuteCaptchaSubject.next({action: this.action});
      this.subscribeToNotifications();
    } else {
      this.showNotifyForm = true;
    }
  }

  public closeForm() {
    if(!!this.notifyForm) {
      this.notifyForm.reset();
    }
    this.showNotifyForm = false;
  }

  public confirmEmail(): void {
    this.notifyForm.markAllAsTouched();
    if (!!this.notifyForm.valid) {
      this.appState.loading = true;
      this.appState.resetAndExecuteCaptchaSubject.next({action: this.action});
      this.subscribeToNotifications();
    }
  }

  private subscribeToNotifications() {
    this.recaptchaResolvedSubscription= this.appState.resolvedCaptcha.subscribe(response => {
    this.captchaResponse = response;
    const enteredEmail = this.notifyForm.controls.email.value;
      if ((!!enteredEmail || !!this.userEmail) && !!this.captchaResponse) {
        this.recaptchaResolvedSubscription?.unsubscribe();
        this.callNewsLetterService(enteredEmail, this.captchaResponse, true, this.action);
      }
    });
  }
  private callNewsLetterService(enteredEmail, captchaRes, invisibleRecaptcha, action?): void {
    this.newsLettersService.subscribe({ email: (this.isLoggedIn) ? this.userEmail : enteredEmail }, 'acp-newsletter', captchaRes, !!action? action : null, invisibleRecaptcha).then((res) => {
      this.appState.loading = false;
      this.isNotified = true;
    }, (error) => {
      this.appState.loading = false;
      this.isNotified = false;
      if(error?.error?.errors[0]?.code === CAPTCHA_BOT_ERROR_CODE) {
        this.modalHelper.showRecaptchaVerificationModal().afterClosed().subscribe((result) => {
          if (!!result) {
            this.appState.loading = true;
            this.callNewsLetterService(enteredEmail, result, false);
          }
        });
       }
      this.recaptchaResolvedSubscription?.unsubscribe();
    });
  }
  private initPlans() {
    this.mobilePlansService.isConfigurationReady.pipe(combineLatest(this.route.params, (isReady, params: Params) => {
      if (isReady) {
        this.prepareFilteredPlans();
        if (!!this.filteredPlans) {
          this.analyticsService.trackProductsImpressions(this.filteredPlans, 'plans-list');
        }
      }
      if (!!params[SHOP_ROUTE_URLS.PARAMS.REPLACE_PLAN]) {
        this.isReplacePlan = params[SHOP_ROUTE_URLS.PARAMS.REPLACE_PLAN];
      }
    })).subscribe();
  }
  private prepareFilteredPlans(): void {
    // Copying base plans
    this.allBasePlans = this.mobilePlansService.allBasePlans;

    // Filtering plans based on conditions
    this.filteredPlans = this.allBasePlans.filter(plan =>
      !!plan.parentId &&
      !!plan?.isSpecialPromotion && plan?.specialPromotion.utm_source === 'acp'
    );
    // Mapping filtered plans to categories
    this.categories = [];
    this.filteredPlans.forEach(plan => {
      if (plan?.subscriptionCycles &&
        !this.categories.some(elm => elm.subscriptionCycles === plan.subscriptionCycles)) {
        this.categories.push({
          subscriptionCycles: plan.subscriptionCycles,
          title: `${plan.subscriptionCycles}-Month`
        });
        this.cdRef.detectChanges();
      }
    });

    this.initDisplayedPlans();
  }
  private initDisplayedPlans() {
    this.subscriptionPlans = this.filteredPlans.filter(plan =>
      plan.subscriptionCycles === this.selectedCategory.subscriptionCycles
    );

    // Sorting subscription plans by data
    this.subscriptionPlans.sort((a, b) => a.data - b.data);
    // this.subscriptionPlans = this.subscriptionPlans.slice(0, 3);
    this.desktopFilteredPlans.push(...this.subscriptionPlans);

    // Checking inner width
    this.checkInnerWidth();

  }

  private checkInnerWidth(): void {
    this.cardExpanded = Array(this.filteredPlans.length).fill(false);
    this.checkPlanCreatingSwiper();
  }

  private initContentful() {
    this.contentfulService.getQuestionsByCategoryId('good2goFaqs', 'close-acp').subscribe(questions => {
      if (!!questions) {
        const allQuestions = questions[0].fields.questions;
        this.firstThreeQuestions = allQuestions.slice(0, 3);
        this.restQuestions = allQuestions.slice(3);
      }
    });
  }
  private callRichText(answerId): void {
    this.contentfulService.getRichText('questions', answerId);
  }

  private checkPlanCreatingSwiper(): void {
    if (!!this.plansSwiper) {
      this.plansSwiper.destroy(true, true);
      this.plansSwiper = null;
    }
    if (this.innerWidth >= 1141) {
      this.cdRef.detectChanges();
      this.createPlansSwiper();
    } else if (this.innerWidth < 1141 ) {
      this.cdRef.detectChanges();
      this.createPlanReponsiveSwiper();
    }
  }

  public createPlansSwiper(): void {
    this.plansSwiper = new Swiper('.plans-swiper', {
      hashNavigation: true,
      modules: [Navigation, Pagination, Keyboard, EffectFade],
      ...this.plansConfig
    });
    this.cdRef.detectChanges();
  }
  public createPlanReponsiveSwiper(): void {
    this.plansSwiper = new Swiper('.plans-swiper', {
      hashNavigation: true,
      modules: [Navigation, Pagination, Keyboard],
      ...this.plansConfig,
      spaceBetween:0,
      centeredSlides: true,
      initialSlide: 1,
      pagination: {
        el: '.slider-pagination',
        renderBullet: (index, className) => `<li style="z-index: 0" class="slick-active ${className}"><button ></button></li>`,
        clickable: true
      },
    });
    this.cdRef.detectChanges();
  }

  public addPlanToCart(suggestedPlan?: MobilePlanItem): void {
    if (!!suggestedPlan) {
      this.selectedPlan = suggestedPlan;
    }
    if (!!this.isReplacePlan) {
      this.addPlan(this.selectedPlan);
      this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CART}`]);
    } else {
      this.checkUser(this.selectedPlan);
    }
  }

  private checkUser(plan: MobilePlanItem): void {
    this.simpleAuthService.userState.pipe(take(1)).subscribe((authState) => {
      const isLoggedIn = !!authState && !authState.isAnonymous;
      if (!isLoggedIn) {
        this.angularFireService.signInAnonymously().then((user) => {
          setTimeout(() => {
            this.checkCart(plan);
          }, 1000);
        });
      } else {
        this.checkCart(plan);
      }
    });
  }

  private checkCart(plan: MobilePlanItem) {
    this.addPlan(plan);
    sessionStorage.removeItem('planFlow');
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CHECK_PHONE}`]);
  }
  private addPlan(plan): void {
    sessionStorage.setItem('planID', JSON.stringify(plan.id));
    this.analyticsService.trackAddToCartGA4(PURCHASE_INTENT.NEW, [plan], plan.price);
    this.mobilePlansService.setBasePlan(plan);
    this.mobilePlansService.setCartType(CART_TYPES.NEW_PLAN);
    this.mobilePlansService.setVoucherData({} as IVoucherData);
    this.mobilePlansService.setActivePlanId('');
    this.mobilePlansService.setAutoRenewPlan(true);
    this.mobilePlansService.setSimCard('');

    if (!this.isReplacePlan) {
      this.mobilePlansService.seteSIM(false);
    }

    sessionStorage.removeItem('useFromBalance');
    sessionStorage.removeItem('useFromReward');
    sessionStorage.removeItem('removeFromCart');
  }
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = document.documentElement.clientWidth;
    this.checkPlanCreatingSwiper();
  }
}
