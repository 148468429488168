
<div class="modal">
    <div class="container model-container mytab-modal">
        <div class="modal-header">
            <img src="assets/img/myTab-blue-logo.svg" alt="myTab" class="modal-heading" />
            <button class="modal-close modal-toggle" (click)="closeDialog()"><i
                    class="icon-close"></i></button>
        </div>
        <div class="modal-body">
            <div class="modal-content">
                <h4>Apply once and use often with flexible 
                    payments with <img src="assets/img/myTab-logo.svg" class="mytab" alt="My Tab" width="70" height="30"/></h4>
                <p class="subheader">Enjoy flexible monthly payments that fit your budget.</p>
                <ul class="list">
                    <li><span>Apply instantly for a private $1,000 - $5,000 credit line</span></li>
                    <li><span>Apply once, use often</span></li>
                    <li><span>No credit card required!</span></li>
                </ul>
                <a class="link" (click)="closeDialog()" [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.FAQS + '/device-financing']"><b>Go to FAQs</b></a>
            </div>
            <div class="actions">
                <button class="button primary" (click)="closeDialog()" aria-label="got it">Got it</button>
            </div>
        </div>
    </div>
    </div>