import { PlatformLocation } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class VerifyAddressModalContext {
  public title: string;
  public message: string;
  public customClass?: string;
  public customHTML?: string;
  public hasCloseLink?: boolean;
}

@Component({
  selector: 'app-verify-address-modal',
  templateUrl: './verify-address-modal.component.html',
  styleUrls: ['./verify-address-modal.component.scss']
})
export class VerifyAddressModalComponent implements OnInit {

  public context;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialogRef<VerifyAddressModalContext>, private location: PlatformLocation) {
    location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
    this.context = data; }

  ngOnInit(): void {
  }
  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  closeDialog(res?): void {
    this.beforeDismiss();
    this.dialog.close(res);
  }

}
