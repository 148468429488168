<div [innerHtml]="jsonLDString"></div>
<div [innerHtml]="plansJsonLDString"></div>
<div class="home-page">
  <section class="home-banner-section">
    <app-banners></app-banners>
  </section>
  <section class="plans-section">
    <div class="page-section">
      <h1 class="title">Choose Unlimited Plan that better fits your needs!</h1>
      <div class="view-plans-part" data-cy="viewAllPlans" (click)="goToPlans()">
        <a>View All Plans</a>
        <img src="/assets/icon/primary-circle-arrow.svg" class="arrow" alt="Circle Arrow Icon" width="20" height="20">
      </div>
    </div>
    <div class="plan-cards-part">
      <div class="gradient left"></div>
      <div class="gradient right"></div>
      <div class="plans-swiper-container swiper-container">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let plan of featuredPlans; let i = index;">
            <div class="swiper-slide plan-card">
              <div class="card-header">
                <h3 class="header">{{plan?.subscriptionCycles}}-Month Plans</h3>
              </div>
              <div class="card-content">
                <p class="start smaller">Starting from</p>
                <div class="price-part">
                  <p class="price"><sup>$</sup>{{(plan?.subscriptionPrice - plan?.promoPrice) /
                    plan?.subscriptionCycles}}<sup class="star">*</sup></p>
                  <p class="per-month smaller">/mo</p>
                </div>
                <p class="caption"><span class="star">*</span><span class="auto-pay-text">Total price reflects $5
                    discount
                    with <b>AutoPay Credit</b>.</span></p>
                <hr class="border">
                <ul class="plan-details">
                  <li class="detail">
                    <img width="12" height="12" src="/assets/icon/primary-check-mark.svg" alt="Check Mark">
                    <p class="smallest">High-speed data <b>per month</b>; then slowed (no overage)</p>
                  </li>
                  <li class="detail last">
                    <img width="12" height="12" src="/assets/icon/primary-check-mark.svg" alt="Check Mark">
                    <p class="smallest"><b>Unlimited</b> talk and text + data access on America’s nationwide networks
                    </p>
                  </li>
                </ul>
                <button class="button primary" (click)="goToPlans(plan?.subscriptionCycles)">View Plans</button>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="hand-swiper">
          <img src="/assets/icon/hand-swipe.svg" alt="Hand Swipe" width="32" height="32">
          <p class="caption">Swipe right</p>
        </div>
        <div class="swiper-button-next plans-swiper-button-next"></div>
        <div class="swiper-button-prev plans-swiper-button-prev"></div>
        <ul class="slick-dots plan-dots slider-pagination plans-slider-pagination"></ul>
      </div>
      <div class="desktop-plans">
        <ng-container *ngFor="let plan of featuredPlans; let i = index;">
          <div class="plan-card" (click)="cardIndex = i"
            [ngClass]="{'center-card-margin': i === 1, 'focus': cardIndex === i}">
            <div class="card-header">
              <h3 class="header">{{plan?.subscriptionCycles}}-Month Plans</h3>
            </div>
            <div class="card-content">
              <p class="start smaller">Starting from</p>
              <div class="price-part">
                <p class="price"><sup>$</sup>{{(plan?.subscriptionPrice - plan?.promoPrice) /
                  plan?.subscriptionCycles}}<sup class="star">*</sup></p>
                <p class="per-month smaller">/mo</p>
              </div>
              <p class="caption"><span class="star">*</span><span class="auto-pay-text">Total price reflects $5 discount
                  with
                  <b>AutoPay Credit</b>.</span></p>
              <hr class="border">
              <ul class="plan-details">
                <li class="detail">
                  <img width="12" height="12" src="/assets/icon/primary-check-mark.svg" alt="Check Mark">
                  <p class="smallest">High-speed data <b>per month</b>; then slowed (no overage)</p>
                </li>
                <li class="detail last">
                  <img width="12" height="12" src="/assets/icon/primary-check-mark.svg" alt="Check Mark">
                  <p class="smallest"><b>Unlimited</b> talk and text + data access on America’s nationwide networks</p>
                </li>
              </ul>
              <button class="button primary" (click)="goToPlans(plan?.subscriptionCycles)">View Plans</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
  <section class="content-flex-section page-section">
    <picture class="image-part">
      <source width="320" height="320" srcset='assets/img/women-with-phone.svg' media="(min-width: 320px)">
      <img width="320" height="320" src="assets/img/women-with-phone.svg" alt="American Women With Mobile Phone">
    </picture>
    <div class="content-part">
      <h2 class="title">Bring your Phone & Keep your Number</h2>
      <p class="sub-title">Bring the phone you love to Good2Go Mobile, and select your new plan!</p>
      <button class="button primary" id="Bring-phone" data-cy="Bring-phone"
        [routerLink]="ACTIVATION_ROUTE_URLS.BASE + '/' + ACTIVATION_ROUTE_URLS.CHECK_PHONE" title="Check compatibility"
        value="Bring phone" aria-label="Bring phone">Check Compatibility</button>
    </div>
  </section>
  <section class="content-flex-section phones page-section">
    <div class="content-part">
      <h2 class="title">Quality Refurbished Phones!</h2>
      <p class="sub-title">Get the phone you love for <b>a great price.</b> Browse our collection of the best
        Refurbished Smartphones deals!</p>
      <button class="button primary" id="view-phones" data-cy="viewPhones"
        [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.BASE] + '/' + PHONES_SHOP_ROUTE_URLS.TYPE"
        title="View Phones" value="View Phones" aria-label="View Phones">View Phones</button>
    </div>
    <picture class="image-part">
      <source width="320" height="320" srcset='assets/img/refurbished-phone.svg' media="(min-width: 320px)">
      <img width="320" height="320" src="assets/img/refurbished-phone.svg" alt="Refurbished Phone">
    </picture>
  </section>
  <section class="content-flex-section coverage page-section">
    <picture class="image-part">
      <source width="396" height="250" srcset='assets/img/home-US-map.svg' media="(min-width: 320px)">
      <img width="396" height="250" src="assets/img/home-US-map.svg" alt="USA Map">
    </picture>
    <div class="content-part">
      <h2 class="title">Powered by America’s Best Networks</h2>
      <p class="sub-title">We’re powered by two of the country’s largest <b>5G - 4G LTE carriers</b>, covering 99% of
        America.</p>
      <button class="button primary" data-cy="checkYourCoverage"
        [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.LANDING_COVERAGE]" title="Check Your Coverage"
        value="Check Your Coverage" aria-label="Check Your Coverage">Check Your Coverage</button>
    </div>
  </section>
  <section class="feedback-section">
    <div class="feedback-titles page-section">
      <h2 class="section-title">What Do Our Customers Say?</h2>
      <p class="info">We grow as individuals by contributing the best to community</p>
    </div>
    <div class="cards-section">
      <div class="testimonial-swiper-container swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide feedback-card" *ngFor="let slide of slides">
            <div class="images-row">
              <img class="avatar" alt="avatar" [src]="slide?.fields?.avatar?.fields?.file?.url" width="80"
                height="80" />
              <img class="stars" alt="stars" src="assets/img/stars.svg" width="100" height="20" />
            </div>
            <p class="content">{{slide?.fields?.userMessage}}</p>
            <div class="details-section">
              <div class="user">
                <p class="name">{{slide?.fields?.userDetails.split(',')[0]}}</p>
                <p class="state">{{slide?.fields?.userDetails.split(',')[1]}}</p>
              </div>
              <div class="testemonial">
                <img src="assets/img/like.svg" alt="like" width="16" height="16" />
                <p class="title">Testimonial</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="backdrop">
        <div class="swipe">
          <img src="assets/img/swipe.svg" alt="swipe left and right" width="28" height="35" />
          <p class="swipe-note">swipe right and left</p>
        </div>
      </div>
    </div>
    <div class="swiper-button-next testimonial-swiper-button-next"></div>
    <div class="swiper-button-prev testimonial-swiper-button-prev"></div>
  </section>
</div>