import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IPageMeta } from '@ztarmobile/zwp-service';
import {
  ACCOUNT_ROUTE_URLS, LOGIN_ROUTE_URLS, ROUTE_URLS, SHOP_ROUTE_URLS,
  SUPPORT_ROUTE_URLS, ACTIVATION_ROUTE_URLS, MIGRATION_ROUTE_URLS, OFFERS_ROUTE_URLS,
  ACP_ROUTE_URLS, OLD_ROUTE_URL, PHONES_SHOP_ROUTE_URLS
} from './app.routes.names';
import { BringPhoneComponent } from './bring-phone/bring-phone.component';
import { HdVoiceComponent } from './hd-voice/hd-voice.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { WifiCallingComponent } from './wifi-calling/wifi-calling.component';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';
import { AuthEmailGuardService } from 'src/providers/user-auth-service/auth-email-guard.service';
import { AcpLandingWindDown } from './acp-flow/landing-acp-wind-down/landing-acp-wind-down.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent, data: {
      title: 'Good2Go Mobile | Prepaid Cell Phone Plans',
      description: `Looking for a cheap cell phone plan? Choose Good2Go Mobile using the largest and fastest 5G network with no long-term commitment, no credit check. Bring your own phone`
    } as IPageMeta,
  },
  { path: LOGIN_ROUTE_URLS.BASE, loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  {
    path: ROUTE_URLS.HOME, component: HomeComponent, data: {
      title: 'Good2Go Mobile | Prepaid Cell Phone Plans',
      description: `Looking for a cheap cell phone plan? Choose Good2Go Mobile using the largest and fastest 5G network with no long-term commitment, no credit check. Bring your own phone`
    } as IPageMeta,
  },
  { path: ACCOUNT_ROUTE_URLS.NAME, loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  {
    path: `${ACCOUNT_ROUTE_URLS.NAME}/${ACCOUNT_ROUTE_URLS.LOGIN}`, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.LOGIN}`, pathMatch: 'full',
    data: { title: 'Good2Go Mobile | Login', description: 'Login in to your Good2go Mobile account' } as IPageMeta
  },
  {
    path: `${ACCOUNT_ROUTE_URLS.NAME}/${ACCOUNT_ROUTE_URLS.SIGNUP}`, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.SIGN_UP}`, pathMatch: 'full',
    data: { title: 'Good2Go Mobile | Signup', description: 'Create your Good2go Mobile account' } as IPageMeta
  },
  {
    path: `${ACCOUNT_ROUTE_URLS.NAME}/${ACCOUNT_ROUTE_URLS.FORGET_PASSWORD}`, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.FORGOT_PASSWORD}`,
    data: { title: 'Good2Go Mobile | Forgot Password', description: 'Reset your Good2go Mobile account password' } as IPageMeta
  },
  { path: ACTIVATION_ROUTE_URLS.NAME, loadChildren: () => import('./activation/activation.module').then(m => m.ActivationModule) },
  { path: SHOP_ROUTE_URLS.NAME, loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) },
  { path: SUPPORT_ROUTE_URLS.NAME, loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
  { path: MIGRATION_ROUTE_URLS.NAME, loadChildren: () => import('./migration/migration.module').then(m => m.MigrationModule) },
  { path: OFFERS_ROUTE_URLS.BASE, loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule) },
  { path: ACP_ROUTE_URLS.NAME, loadChildren: () => import('./acp-flow/acp-flow.module').then(m => m.AcpFlowModule), canActivate: [AuthEmailGuardService] },
  {
    path: ROUTE_URLS.BRING_PHONE, component: BringPhoneComponent, data: {
      title: 'Keep Your Phone | Good2Go Mobile',
      description: 'Good2Go Mobile supports most GSM unlocked phones'
    } as IPageMeta
  },
  {
    path: ROUTE_URLS.HD_VOICE, component: HdVoiceComponent, data: {
      title: 'Good2Go HD Voice',
      description: 'Good2Go HD Voice improves the sound quality of your calls and lets you multi-task on your phone'
    } as IPageMeta
  },
  {
    path: ROUTE_URLS.WIFI_CALLING, component: WifiCallingComponent, data: {
      title: 'Good2Go Wi-Fi Calling',
      description: 'Good2Go Wi-Fi Calling lets you talk and text from locations where it’s hard to get a strong cellular signal.'
    } as IPageMeta
  },
  {
    path: ROUTE_URLS.REFER_FRIEND, component: ReferFriendComponent, data: {
      title: 'Refer a Friend | Good2Go Mobile',
      description: 'Friends who are new to Good2Go get $10 and you get $10 after they activate their first purchase.'
    } as IPageMeta
  },
  {
    path: ROUTE_URLS.EBB, redirectTo: `${ROUTE_URLS.ACP_WINDDOWN}`, pathMatch: 'full'
  },
  {
    path: ROUTE_URLS.POSTER_ACP, redirectTo: `${ROUTE_URLS.ACP_WINDDOWN}`, pathMatch: 'full'
  },
  {
    path: ROUTE_URLS.POSTER_ACP_SMALL, redirectTo: `${ROUTE_URLS.ACP_WINDDOWN}`, pathMatch: 'full'
  },
  {
    path: ROUTE_URLS.ACP, redirectTo: `${ROUTE_URLS.ACP_WINDDOWN}`, pathMatch: 'full'
  },
  {
    path: ROUTE_URLS.ACP_WINDDOWN, component: AcpLandingWindDown, data: {
      title: 'The FCC is Taking Steps to Wind Down the Affordable Connectivity Program (ACP) | Good2Go Mobile',
      description: 'The Federal Communications Commission (FCC) has begun taking steps to wind down the Affordable Connectivity Program. ACP benefits will end in April 2024 unless Congress Provides additional Funding.'
    } as IPageMeta
  },
  { path: ROUTE_URLS.DATA_SETUP, redirectTo: `${SUPPORT_ROUTE_URLS.NAME}/${SUPPORT_ROUTE_URLS.DATA_SETUP}`, pathMatch: 'full' },
  { path: ROUTE_URLS.CHECK_PHONE, redirectTo: `${ACTIVATION_ROUTE_URLS.NAME}/${ACTIVATION_ROUTE_URLS.CHECK_PHONE}`, pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.PLANS}/${OLD_ROUTE_URL.CATAGORY}`, redirectTo: '/', pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.PLAN}/${OLD_ROUTE_URL.CATAGORY}`, redirectTo: '/', pathMatch: 'full' },
  { path: OLD_ROUTE_URL.OPENINTERNET, redirectTo: '/', pathMatch: 'full' },
  { path: OLD_ROUTE_URL.CONTACTUS, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.CONTACT_US}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.BEST_COVERAGE, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.LANDING_COVERAGE}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.WHYG2G, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.WHY_GOOD2GO}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.HOW_IT_WORKS, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.HOW_IT_WORKS}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.PHONES, redirectTo: `${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PHONES.TYPE}/${PHONES_SHOP_ROUTE_URLS.TYPE}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.COVERAGE, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.COVERAGE}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.INTERNATIONAL_CALLING, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.INTERNATIONAL_CALLING}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.INTERNATIONALCALLING, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.INTERNATIONAL_CALLING}`, pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.SHOP}/${OLD_ROUTE_URL.PHONES}`, redirectTo: `${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PHONES.TYPE}/${PHONES_SHOP_ROUTE_URLS.TYPE}`, pathMatch: 'full' },
  { path: `${SUPPORT_ROUTE_URLS.NAME}/${OLD_ROUTE_URL.STORE_LOCATOR}`, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.STORE_LOCATOR}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.INDEX, redirectTo: '/', pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.DEVICES}/${OLD_ROUTE_URL.BYOD_SPRINT}`, redirectTo: '/', pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.MYACCOUNT}/${OLD_ROUTE_URL.DATA_SETUP}`, redirectTo: ROUTE_URLS.DATA_SETUP, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.ABOUT_G2G, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.ABOUT_GOOD2GO}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.HEARING_AID_COMPATIBILITY, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.HEARING_AID_COMPATIBILITY}`, pathMatch: 'full' },
  { path: `${ACCOUNT_ROUTE_URLS.NAME}/${OLD_ROUTE_URL.LEGACY_LOGIN}`, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.LEGACY_LOGIN}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.FIND_STORE, redirectTo: '/', pathMatch: 'full' },
  { path: OLD_ROUTE_URL.PHONES, redirectTo: '/', pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.MYACCOUNT}/${OLD_ROUTE_URL.ACCOUNT}`, redirectTo: ACCOUNT_ROUTE_URLS.BASE, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.FORGET_PASSWORD, redirectTo: `${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.FORGET_PASSWORD}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.LOGIN, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.LOGIN}`, pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.ACCOUNT}/${OLD_ROUTE_URL.WELCOME}`, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.WELCOME}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.SITEMAP, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.SITEMAP}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.UNLOCKING_POLICY, redirectTo: ROUTE_URLS.LEGAL.UNLOCKING_POLICY, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.PRIVACY_POLICY, redirectTo: ROUTE_URLS.LEGAL.PRIVACY_POLICY, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.ACTIVATE, redirectTo: '/', pathMatch: 'full' },
  { path: OLD_ROUTE_URL.HOWITWORKS, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.HOW_IT_WORKS}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.WHY_G2G, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.WHY_GOOD2GO}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.COVERAGE_CAP, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.COVERAGE}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.HOW_TO_SWITCH, redirectTo: '/', pathMatch: 'full' },
  { path: OLD_ROUTE_URL.ABOUT_G2G_CAP, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.ABOUT_GOOD2GO}`, pathMatch: 'full' },
  { path: OLD_ROUTE_URL.BROADBAND, redirectTo: '/', pathMatch: 'full' },
  { path: OLD_ROUTE_URL.CONTACT, redirectTo: `${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.CONTACT_US}`, pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.STORE}/${OLD_ROUTE_URL.CATAGORY}`, redirectTo: '/', pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.DEVICES}/${OLD_ROUTE_URL.BYOD}`, redirectTo: '/', pathMatch: 'full' },
  { path: OLD_ROUTE_URL.MYACCOUNT, redirectTo: ACCOUNT_ROUTE_URLS.BASE, pathMatch: 'full' },
  { path: `${ROUTE_URLS.LEGAL.TERMS_AND_CONDITIONS}/${OLD_ROUTE_URL.CARD_LINKING}`, redirectTo: '/', pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.SHOP}/${OLD_ROUTE_URL.PHONES}/${OLD_ROUTE_URL.PHONES_TYPE}`, redirectTo: `${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PHONES.TYPE}/${PHONES_SHOP_ROUTE_URLS.TYPE}`, pathMatch: 'full' },
  { path: `${OLD_ROUTE_URL.SUPPORT}/${OLD_ROUTE_URL.SHUTDOWN_3G}`, redirectTo: ROUTE_URLS.HOME, pathMatch: 'full' },
  {
    path: ROUTE_URLS.PLANS, redirectTo: `${SHOP_ROUTE_URLS.NAME}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}`, data: {
      title: `Unlimited Talk & Text 4G LTE Cell Phone Plans | Good2Go Mobile`, description:
        // eslint-disable-next-line max-len
        `The best cell phone deal just got better! Get all the power of the nation’s largest networks for less.`
    } as IPageMeta
  },
  { path: ROUTE_URLS.LOGIN, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.LOGIN}`, pathMatch: 'full' },
  {
    path: ROUTE_URLS.ACP_DETAILS_LONG, redirectTo: `${ROUTE_URLS.HOME}`, pathMatch: 'full'
  },
  {
    path: ROUTE_URLS.ACP_DETAIL, redirectTo: `${ROUTE_URLS.HOME}`, pathMatch: 'full'
  },
  { path: '**', component: NotFoundComponent, data: { title: 'Page Not Found', description: 'Please make sure to enter a valid Good2gomobile page url' } as IPageMeta },
];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {});

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
