<div class="landing-acp-wind-down-page">
  <section class="banner-section">
    <picture class="banner-image-container">
      <source width="639" height="180" srcset='assets/img/banners/ACP-landing-page-Mobile.webp'
        media="(max-width: 639.9px)" type="image/webp">
      <source width="639" height="180" srcset='assets/img/banners/ACP-landing-page-Mobile.png'
        media="(max-width: 639.9px)" type="image/png">
      <source width="1024" height="184" srcset='assets/img/banners/ACP-landing-page-Tablet.webp'
        media="(max-width: 1024.9px)" type="image/webp">
      <source width="1024" height="184" srcset='assets/img/banners/ACP-landing-page-Tablet.png'
        media="(max-width: 1024.9px)" type="image/png">
      <source width="2000" height="184" srcset='assets/img/banners/ACP-landing-page-desktop.webp'
        media="(min-width: 1800px)" type="image/webp">
      <source width="2000" height="184" srcset='assets/img/banners/ACP-landing-page-desktop.png'
        media="(min-width: 1800px)" type="image/png">
      <img width="2000" height="342" src="assets/img/banners/ACP-landing-page-desktop.png"
        alt="Good2Go ACP Page Banner">
    </picture>
    <div class="banner-container page-section">
      <div class="banner-text-content">
        <p class="title"><img src="assets/icon/fcc-logo.svg" alt="FCC">GOVERNMENT BENEFIT PROGRAM</p>
        <h1 class="header">April 2024 Announced as the Final fully funded month for ACP benefits</h1>
        <h5 class="description">Due to the lack of additional funding for the ACP, the FCC announces that the <b>last fully funded month</b> for the ACP discount is April 2024.</h5>
      </div>
      
    </div>
    <div class="sup-container">
      <div class="notify-box" *ngIf="!isNotified"  [ngClass]="{'center': !showNotifyForm}">
        <div class="img-part">
          <img src="assets/icon/001-mail 1.svg" alt="mail us" />
          <a>
            <b>Want updates?</b>
          </a>
        </div>
        <div class="link-part">
          <a class="link" *ngIf="!showNotifyForm" id="notifyMeBtn" (click)="openForm()">Notify me
            when available</a>
        </div>
        <form *ngIf="!!showNotifyForm" [formGroup]="notifyForm" id="notifyForm">
          <div class="form-field">
            <div class="input-flex">
              <div>
                <label class="outlined-label-input">
                  <input class="input-field" id="email" type="text" name="email" placeholder=" " formControlName="email"
                    required autocomplete="email" />
                  <span>Enter Email</span>
                </label>
                <label class="validation-message"
                *ngIf="notifyForm.controls.email.hasError('required') && notifyForm.controls.email.touched"
                id="required-email-msg">
                Email Address is required
              </label>
              <label class="validation-message" *ngIf="notifyForm.controls.email.hasError('pattern')"
                id="invalid-email-msg">
                Invalid Email. Try Again
              </label>
              </div>
              <div class="action">
                <button class="button primary" id="confirmEmailBtn" (click)="confirmEmail()">Submit</button>
                <button class="button tertiary close-button-desktop-shown" id="closeFormBtn" (click)="closeForm()">Close</button>
                <a (click)="closeForm()" class="close-button-desktop-hidden">
                  <img  src="assets/icon/header-close-icon.svg" width="40" height="40" alt="Warning Icon" class="close-icon">
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="after-notify-box" *ngIf="!!isNotified">
        <img src="assets/icon/green-check.svg" width="32" height="32" alt="Green Check Icon">
        <p class="smallest" id="email-subscribed-success">You’ll be notified via email on ACP program updates</p>
      </div>
    </div>
  </section>
  <section class="plans-offer-section" [class.extra-padding]="addExtraHeight">
    <div class="section-header">
      <h2 class="tertiary">
        Don't worry! We've got you.
      </h2>
      <p>
        Pick one of our affordable plans and get <b>50% off</b> your first month.
      </p>
    </div>
    <div>
      <img src="assets/img/50-off.svg" alt="50% Off!">
    </div>
    <div class="plans-container">
      <div class="desktop-plan-cards" *ngIf="innerWidth >= 1141 && desktopFilteredPlans?.length > 0">
        <div *ngFor="let plan of desktopFilteredPlans; let i = index;" class="plan-card"
          [class.right-sperator]="i === desktopFilteredPlans.length - 2" [class.poped]="mouseOvered[i]"
          [class.expanded]="cardExpanded[i]" [class.middle]="i == 1" (mouseover)="popCard(i, true)"
          (mouseout)="popCard(i, false)">
          <div class="card-header">
            <img src="/assets/img/50Offer.png" alt="50% Off!" class="offer">
            <b class="original-price"><span class="stroke"></span><span class="currency">$</span>{{plan?.price - plan?.promoPrice}}*</b>
            <h5 *ngIf="!!plan?.category" class="category">{{plan?.category}}</h5>
            <div class="plan-data">
              <div>
                <p class="data">{{plan?.details?.data/1024}}<span>GB</span>
                </p>
              </div>
              <p class="sperator"></p>
              <div>
                <p class="price"><span class="currency">$</span>
                  {{(plan?.price- plan?.promoPrice ) *discountPercent }}
                </p>
              </div>
            </div>
          </div>
          <div class="plan-data-disclaimers">
            <span class="disclaimer">5G - 4G LTE Data</span>
            <span class="disclaimer">Price for one month</span>
          </div>
          <div class="card-body">
            <p class="intro">Original price ${{plan?.price}}</p>
            <p class="disclaimer">*Price reflects $5 AutoPay Discount.</p>
          </div>
          <div class="actions">
            <button class="button primary" (click)="addPlanToCart(plan, i, true)">
              Add to cart
            </button>
          </div>
          <div>
            <a (click)="showDetails(i, !cardExpanded[i])" class="link">{{!!cardExpanded[i] ? 'View less' : 'View more'}} <img
                [src]="!!cardExpanded[i]? '/assets/icon/faqs-up-arrow-icon.svg' : '/assets/icon/faqs-down-arrow-icon.svg'"
                alt="arrow"></a>
          </div>
          <div class="card-body extra-details" *ngIf="!!cardExpanded[i]" [@slideInOut]="transitionState[i]">
            <ng-container *ngIf="!plan?.ebb">
              <p class="smaller intro">INCLUDES</p>
              <div class="details">
                <img src="/assets/icon/purple-check.svg" alt="tick">
                <p class="smaller"><b>{{plan?.details?.data/1024}}GB</b> high-speed data per month; then slowed (no
                  overage)</p>
              </div>
              <div class="details">
                <img src="/assets/icon/purple-check.svg" alt="tick">
                <p class="smaller"><b>Unlimited</b> talk & text + data access on America’s
                  nationwide networks</p>
              </div>
              <div class="details">
                <img src="/assets/icon/purple-check.svg" alt="tick">
                <p class="smaller">Works with network compatible <b>unlocked iPhone and Android phones</b></p>
              </div>
              <div class="details">
                <img src="/assets/icon/purple-check.svg" alt="tick">
                <p class="smaller"><b>FREE</b> SIM Card Activation Kit. <b>FREE</b> Shipping.</p>
              </div>
            </ng-container>
            <ng-container *ngIf="!!plan?.ebb">
              <p class="smaller intro">ACP Enrollments End February 7, 2024</p>
              <div class="details">
                <p class="smaller">The Affordable Connectivity Program {{istemporarily?'will temporarily ':'has '}}<b>{{istemporarily?'stop ':'stopped'}}accepting new
                    applications from February 7, 2024.</b>
              </div>
              <div class="action">
                <a class="link" (click)="goToAcpWindDown()">Learn more<img src="/assets/icon/next-blue-arrow.svg"
                    alt="next-arrow"></a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="responsive-plan-cards" [class.hidden]="innerWidth <= 1140 && subscriptionPlans?.length > 0">
        <div class="plans-swiper swiper-container">
          <div class="swiper-wrapper">
            <ng-container *ngFor="let plan of subscriptionPlans; let i = index;">
              <div class="swiper-slide plan-card" [class.right-sperator]="i === desktopFilteredPlans.length - 2"
                [class.expanded]="cardExpanded[i]" [class.middle]="i == 1" [class.poped]="mouseOvered[i]" [class.first-slide]="i === 0">
                <div class="card-header">
                  <img src="/assets/img/50Offer.png" alt="50% Off!" class="offer">
                  <b class="original-price"><span class="stroke"></span><span class="currency">$</span>{{plan?.price
                    -
                    plan?.promoPrice}}*</b>
                  <h5 *ngIf="!!plan?.category" class="category">{{plan?.category}}</h5>
                  <div class="plan-data">
                    <div>
                      <p class="data">{{plan?.details?.data/1024}}<span>GB</span>
                      </p>
                    </div>
                    <p class="sperator"></p>
                    <div>
                      <p class="price"><span class="currency">$</span>
                        {{(plan?.price- plan?.promoPrice) *discountPercent }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="plan-data-disclaimers">
                  <span class="disclaimer">5G - 4G LTE Data</span>
                  <span class="disclaimer">Price for one month</span>
                </div>
                <div class="card-body">
                  <p class="intro">Original price ${{plan?.price}}</p>
                  <p class="disclaimer">*Price reflects $5 AutoPay Discount.</p>
                </div>
                <div class="actions">
                  <button class="button primary" (click)="addPlanToCart(plan, i, true)">
                    Add to cart
                  </button>
                </div>
                <div>
                  <a (click)="showDetails(i, !cardExpanded[i])" class="link">{{!!cardExpanded[i] ? 'View less' : 'View more'}} <img
                      [src]="!!cardExpanded[i]? '/assets/icon/faqs-up-arrow-icon.svg' : '/assets/icon/faqs-down-arrow-icon.svg'"
                      alt="arrow"></a>
                </div>
                <div class="card-body extra-details" *ngIf="!!cardExpanded[i]">
                  <ng-container *ngIf="!plan?.ebb">
                    <p class="smaller intro">INCLUDES</p>
                    <div class="details">
                      <img src="/assets/icon/purple-check.svg" alt="tick">
                      <p class="smaller"><b>{{plan?.details?.data/1024}}GB</b> high-speed data per month; then slowed
                        (no
                        overage)</p>
                    </div>
                    <div class="details">
                      <img src="/assets/icon/purple-check.svg" alt="tick">
                      <p class="smaller"><b>Unlimited</b> talk & text + data access on America’s
                        nationwide networks</p>
                    </div>
                    <div class="details">
                      <img src="/assets/icon/purple-check.svg" alt="tick">
                      <p class="smaller">Works with network compatible <b>unlocked iPhone and Android phones</b></p>
                    </div>
                    <div class="details">
                      <img src="/assets/icon/purple-check.svg" alt="tick">
                      <p class="smaller"><b>FREE</b> SIM Card Activation Kit. <b>FREE</b> Shipping.</p>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!!plan?.ebb">
                    <p class="smaller intro">ACP Enrollments End February 7, 2024</p>
                    <div class="details">
                      <p class="smaller">The Affordable Connectivity Program {{istemporarily?'will temporarily ':'has '}}<b>{{istemporarily?'stop ':'stopped '}}accepting new
                          applications from February 7, 2024.</b>
                    </div>
                    <div class="action">
                      <a class="link" (click)="goToAcpWindDown()">Learn more<img src="/assets/icon/next-blue-arrow.svg"
                          alt="next-arrow"></a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="hand-swiper" *ngIf="selectedCategory?.subscriptionCycles !== 0">
            <img src="/assets/icon/hand-swipe.svg" alt="Hand Swipe" width="32" height="32">
            <p class="caption"><b>Swipe right</b></p>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
          <ul class="slick-dots plan-dots slider-pagination"></ul>
        </div>
      </div>
    </div>
  </section>
  <section class="advantages-section">
    <h1 class="title">Our plans come with several advantages:</h1>
    <div class="advantages">
      <div>
        <img src="assets/img/advantage-reliable.svg" alt="Reliable Service" />
        <p>Reliable Service</p>
      </div>
      <div>
        <img src="assets/img/advantage-affordable.svg" alt="Affordable Pricing" />
        <p>Affordable Pricing</p>
      </div>
      <div>
        <img src="assets/img/advantage-diverse.svg" alt="Diverse Options" />
        <p>Diverse Options</p>
      </div>
    </div>
  </section>

  <section class="faqs-section">
    <div class="faqs-content">
      <h2 class="faqs-title">Got questions? We’re here to answer!</h2>
      <p class="faqs-sub-title">Browse through the most frequently asked questions.</p>
      <div class="ebb-questions">
        <div class="row">
          <div class="twelve questions">
            <div *ngFor="let qst of firstThreeQuestions">
              <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                [isActive]="qst?.fields?.questionId === questionIdParam"
                (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
              </app-question-answer>
            </div>
            <div *ngIf="!!showMore">
              <div *ngFor="let qst of restQuestions">
                <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                  [isActive]="qst?.fields?.questionId === questionIdParam"
                  (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                  <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                </app-question-answer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="more-or-less-faqs" *ngIf="!showMore"><a (click)="showMore = true">More FAQs</a></p>
      <p class="more-or-less-faqs" *ngIf="!!showMore"><a (click)="showMore = false; questionId = 'q0'">Less
          FAQs</a>
    </div>
  </section>
</div>