/* eslint-disable */
import { enableProdMode, NgModuleRef } from '@angular/core';
import { disableDebugTools } from '@angular/platform-browser';
import { Environment } from './model';

export const ENV_FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAUwn5YLtuu0b-xL2OgvhYVFE7Sp0p-hlY',
  authDomain: 'prod-good2go.firebaseapp.com',
  databaseURL: 'https://prod-good2go.firebaseio.com',
  projectId: 'prod-good2go',
  storageBucket: 'prod-good2go.appspot.com',
  messagingSenderId: '697883677675'
};
export const Title1 = 'stage';
export const Title: string = 'Good2Go Mobile | Prepaid Cell Phone Plans' ;
export const Description ="Looking for a cheap cell phone plan? Choose Good2Go Mobile using the largest and fastest 5G network with no long-term commitment, no credit check. Bring your own phone" ;
export const ENDPOINT_URL = 'https://g2g-zwp-stage.ztarmobile.io';
export const ACP_CALLBACK_URL =  'https://g2g-stage.ztarmobile.io';
export const ENV_ENDPOINT_URL: string = '';
export const BFF_ENDPOINT_URL = 'http://g2g-zwp-stage.ztarmobile.io' ;
export const CUSTOMER_CARE_NUMBER: string = '8004163003';
export const CAPTCHA_SITE_ID = '6LeOIS0UAAAAACRW1uF2Vhq5zA9ciLvWKZrZXE9P';
// export const CAPTCHA_SITE_ID = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; //this is the testing key for protractor
export const INVISIBLE_CAPTCHA_ID = '6LebMcMpAAAAAH0JWFGp1ubZC8R_rEGuVoMVL6pz';
export const BUILD_VERSION = require('../../package.json').version ;
export const BUILD_DATE = JSON.stringify((new Date()).toISOString());
export const GEOCODE_API_KEY = 'AIzaSyBLEp1LJrBqnCibrCdjkptaOB66y68B-aU';
export const GTM_API_KEY = 'GTM-5BHGWDH';
export const GOOGLE_ADSENSE_CLIENT = 'ca-pub-6676283552039995';
export const GOOGLE_CLIENT_ID = '137465270934-7gipmpdjkmdlb22m665n151897su6ln9.apps.googleusercontent.com';
export const PASSWORD_PATTERN = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,12}$';
export const FUTURE_PAY_SCRIPT = 'https://cdn.sandbox.futurepay.com/js-sdk/index.js';
export const CONTENTFUL= {
    spaceId: 'oufplo9dsind',
    token: 'di6HPlO0KiS3-WuOWKcIaIe1rNTSpZ9Ut5pytiaMX10',
    environment: 'production'
};
export const WEBSITE_URL = 'https://g2g-stage.ztarmobile.io';
export const ZMP_G2G_BFF_ENDPOINT_URL = 'https://g2g-zmp-stage.ztarmobile.io';
export const environment: Environment = {
  production: false,
  showDevModule: true,

  /** Angular debug tools in the dev console
   * https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
   * @param modRef
   * @return {any}
   */
  decorateModuleRef(modRef: NgModuleRef<any>) {
    disableDebugTools();
    return modRef;
  },
  ENV_PROVIDERS: [

  ]
};

