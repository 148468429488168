import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlatformLocation } from '@angular/common';

export class IsLteModalContext {
  public title: string;
  public customClass?: string;
}

@Component({
  selector: 'app-is-lte-modal',
  templateUrl: './is-lte-modal.component.html',
})
export class IsLteModalComponent implements OnInit{
  public context: any;
  public lteOptionsForm: FormGroup;
  public selectedOption: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<IsLteModalContext>, private location: PlatformLocation) {
    location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
    this.context = data;
  }

  ngOnInit(): void {
    this.lteOptionsForm = new FormGroup({
      option: new FormControl('', Validators.required)
    });
  }
  public checkCoverage(){
    if (!!this.lteOptionsForm.valid) {
      this.selectedOption = this.lteOptionsForm.get('option').value;
      if (this.selectedOption === 'Yes') {
       this.selectedOption = true;
      }
      else if (this.selectedOption === 'No'){
        this.selectedOption = false;
      }
      else{
        this.selectedOption = null;
      }
      this.beforeDismiss();
      this.dialog.close(this.selectedOption);
    }
  }
  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }
 closeDialog(): void {
  this.beforeDismiss();
    this.dialog.close();
  }
}
