import { PlatformLocation } from '@angular/common';
import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SUPPORT_ROUTE_URLS } from 'src/app/app.routes.names';

export class MyTabModalContext {
  public title: string;
  public hasCloseLink?: boolean;
  public customClass?: string;
  public notBeforeSkipping?: boolean;
}

@Component({
  selector: 'app-my-tab-modal',
  templateUrl: './my-tab-modal.component.html',
  styleUrls: ['./my-tab-modal.component.scss']
})
export class MyTabModalComponent implements OnInit {
  public context: any;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<MyTabModalContext>, 
  private router: Router, private location: PlatformLocation) {
    location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
    this.context = data;
   }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.beforeDismiss();
    this.dialog.close();
  }
  beforeDismiss(): boolean {
    return this.beforeClose();
  } 

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
        document.body.classList.remove('modal-open');
    }
    return false;
  }
}
