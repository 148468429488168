<div class="modal">
    <div class="container model-container {{context.customClass}}">
        <div class="modal-header">
            <h4 class="modal-heading">{{context.title}}</h4>
            <button *ngIf="context.hasCloseLink" class="modal-close modal-toggle" (click)="closeDialog()"><i
                    class="icon-close"></i></button>
        </div>
        <div class="modal-body">
            <div class="modal-content">
                <div class="row">
                    <div class="tmo-skip-content">
                        <p class="desc"><span *ngIf="!context.notBeforeSkipping">By skipping,
                            </span>
                            you’ll receive a physical SIM. Please make sure your phone is 4G VoLTE/5G compatible.
                          </p>
                        <div class="actions">
                            <button class="button primary" data-cy="sendMePhysicalSIM"
                                (click)="skipModalOption(true)">Continue</button>
                        </div>
                        <div class="check-section">
                            <p class="smaller not-sure">Want an eSIM?</p>
                            <button data-cy="checkDeviceForESim" class="button tertiary" (click)="skipModalOption()">Check Device for eSIM</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>