import { Component, Inject } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class successModalContext {
  public message: string;
  public buttonText: string;
  public customClass?: string;
}

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html'
})
export class successModalComponent{
  public context: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<successModalContext>, private location: PlatformLocation) {
      this.context = data;
      location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
  }
  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
      return this.beforeClose();
  }
  closeDialog(result): void {
    this.beforeDismiss();
      this.dialog.close(result);
  }

  done(): void {
    this.beforeDismiss();
    this.dialog.close(true);
  }
}

