<div [innerHtml]="faqLD"></div>
<div class="refer-friend-page container-flex">
    <section class="header-section">
        <div class="header-text-part">
            <h1 class="title">Get $10 for <br><span class="no-wrap">every friend you
                    invite</span></h1>
            <p class="desc">Friends who are new to Good2Go get $10 and you get $10 after they activate their first
                purchase.</p>
            <div class="invite-friends-button">
                <button class="button primary"
                    [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.REFER_FRIEND]">Invite
                    Friends</button>
            </div>
        </div>
        <div class="header-image-part">
            <h1 class="responsive-title">Get $10 for <br><span class="no-wrap">every friend you
                    invite</span></h1>
            <picture>
                <source srcset='assets/img/refer-friend-header-mobile.webp' media="(max-width: 639.9px)"
                    type="image/webp">
                <source srcset='assets/img/refer-friend-header-mobile.png' media="(max-width: 639.9px)"
                    type="image/png">
                <source srcset='assets/img/refer-friend-header-tablet.webp' media="(max-width: 1024.9px)"
                    type="image/webp">
                <source srcset='assets/img/refer-friend-header-tablet.png' media="(max-width: 1024.9px)"
                    type="image/png">
                <source srcset='assets/img/refer-friend-header-desktop.webp' media="(min-width: 1025px)"
                    type="image/webp">
                <source srcset='assets/img/refer-friend-header-desktop.png' media="(min-width: 1025px)"
                    type="image/png">
                <img src="assets/img/refer-friend-header-desktop.png" alt="good2go refer friend header image">
            </picture>
        </div>
    </section>
    <section class="referral-work-section">
        <h2 class="title">How referrals work</h2>
        <div class="how-steps">
            <div class="how-step">
                <div class="step-icon">
                    <picture>
                        <img src="assets/icon/email-black.svg" alt="email icon">
                    </picture>
                </div>
                <p class="step-title">Invite friends to Good2Go Mobile</p>
                <p class="smallest step-info">Invite people who don’t have a Good2Go mobile account to sign up through your
                    referral
                    link.</p>
            </div>
            <div class="how-step margin-left">
                <div class="step-icon">
                    <picture>
                        <img src="assets/icon/discount.svg" alt="discount icon">
                    </picture>
                </div>
                <p class="step-title">They get a discount</p>
                <p class="smallest step-info">Friends who sign up for Good2Go Mobile with your link will get $10 credit to use on
                    their next renew, and you’ll get $10 for each friend that activates a Good2Go Mobile plan.</p>
            </div>
            <div class="how-step margin-left bottom-0">
                <div class="step-icon">
                    <picture>
                        <img src="assets/icon/manage.svg" alt="manage icon">
                    </picture>
                </div>
                <p class="step-title">Manage your referrals on the go!</p>
                <p class="smallest step-info">Manage your referrals online. You can also track referrals, send more referrals and
                    check account credits. <span *ngIf="!isLoggedIn">Don’t have an account? <a
                            [routerLink]="['/' + LOGIN_ROUTE_URLS.BASE + '/' + LOGIN_ROUTE_URLS.SIGN_UP]">Sign up
                            today!</a></span></p>
            </div>
        </div>
    </section>
    <section class="third-section">
        <div class="img-desc-part bottom">
            <div class="image-part">
                <picture>
                    <source srcset='assets/img/girl-holding-coffee-mobile.webp' media="(max-width: 639.9px)"
                        type="image/webp">
                    <source srcset='assets/img/girl-holding-coffee-mobile.png' media="(max-width: 639.9px)"
                        type="image/png">
                    <source srcset='assets/img/girl-holding-coffee.webp' media="(min-width: 640px)" type="image/webp">
                    <source srcset='assets/img/girl-holding-coffee.png' media="(min-width: 640px)" type="image/png">
                    <img src="assets/img/girl-holding-coffee.png" alt="girl holding coffee image">
                </picture>
            </div>
            <div class="description-part">
                <h2>Refer your friends, earn up to $250/year</h2>
                <p class="step-description">Invited friends who sign up with Good2Go get $10 and you’ll receive $10
                    after their purchase. You’ll both get a $10 account credit 15 days from your friend activating their
                    purchased plan on Good2Go Mobile and the credit will automatically be applied to your subsequent
                    renewals.</p>
            </div>
        </div>
        <div class="desc-img-part bottom">
            <div class="description-part">
                <h2>Invite Friends</h2>
                <p class="step-description">Simply sign on to your Account page and select Refer a Friend. We can email
                    your friends on your behalf or send them your referral link via email, text message or social media.
                    Once you’ve sent out invitations, you’ll be able to come back anytime and track your referral
                    status.<br><br><span class="info">Max $250 in account per calendar year.</span></p>
            </div>
            <div class="image-part top" >
                <picture>
                    <source srcset='assets/img/women-man-with-phones-mobile.webp' media="(max-width: 639.9px)"
                        type="image/webp">
                    <source srcset='assets/img/women-man-with-phones-mobile.png' media="(max-width: 639.9px)"
                        type="image/png">
                    <source srcset='assets/img/women-man-with-phones.webp' media="(min-width: 640px)" type="image/webp">
                    <source srcset='assets/img/women-man-with-phones.png' media="(min-width: 640px)" type="image/png">
                    <img src="assets/img/women-man-with-phones.png" alt="women and man with phone image">
                </picture>
            </div>
        </div>
        <div class="img-desc-part">
            <div class="image-part align-center">
                <picture>
                    <source srcset='assets/img/referral-status-mobile.webp' media="(max-width: 639.9px)"
                        type="image/webp">
                    <source srcset='assets/img/referral-status-mobile.png' media="(max-width: 639.9px)"
                        type="image/png">
                    <source srcset='assets/img/referral-status.webp' media="(min-width: 640px)" type="image/webp">
                    <source srcset='assets/img/referral-status.png' media="(min-width: 640px)" type="image/png">
                    <img src="assets/img/referral-status.png" class="phone-img" alt="your referral status image">
                </picture>
            </div>
            <div class="description-part">
                <h2>Track Your Invitations</h2>
                <p class="step-description">You can check the status of credits earned. You’ll be able to track referral
                    codes sent, retrieved, redeemed and when your friends activate their purchased Good2Go Mobile plans.
                    At this point, both you and your friends have earned a $10 credit that has been applied to each of
                    your accounts.</p>
            </div>
        </div>
    </section>
    <section class="actions-section">
        <div class="invite-friends-button">
            <button class="button primary"
                [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.REFER_FRIEND]">Invite
                Friends</button>
        </div>
        <div class="read-terms-link">
            <a
                [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS + '/' + SUPPORT_ROUTE_URLS.REFER_A_FRIEND]">Read
                the Terms</a>
        </div>
    </section>
    <section class="faqs-section">
        <h2 class="title">Common questions</h2>
        <div class="refer-questions">
            <div class="row">
                <div class="twelve questions">
                    <div *ngFor="let qst of firstThreeQuestions">
                        <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                            [isActive]="qst?.fields?.questionId === questionIdParam"
                            (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                            <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                        </app-question-answer>
                    </div>
                    <div *ngIf="!!showMore">
                        <div *ngFor="let qst of restQuestions">
                            <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                                [isActive]="qst?.fields?.questionId === questionIdParam"
                                (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                                <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                            </app-question-answer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="more-or-less-faqs" (click)="showMore = true" *ngIf="!showMore">More FAQs</a>
        <a class="more-or-less-faqs" *ngIf="!!showMore" (click)="showMore = false; questionId = 'q0'">Less FAQs</a>
    </section>
</div>