<div class="modal">
<div class="container model-container {{context.customClass}}">
  <div class="modal-header">
    <h2 class="modal-heading">{{context.title}}</h2>
    <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
  </div>
  <div class="modal-body">
    <form #userInputForm="ngForm">
      <div class="modal-content">

        <fieldset class="twelve">
          <label class="label" for="userInputField">{{context.labelText}}</label>
          <input id="userInputField" type="text" name="userInputField" [(ngModel)]="userInputValue" #userInput
            required autocomplete="user-input" [minLength]="checkLength()" [maxlength]="checkLength()">
          <label class="validation-message" *ngIf="userInputValue?.length < validLength">Invalid Iccid</label>
          <div class="primary-col"><a (click)="showIccidModal()">What is an ICCID number?</a></div>
        </fieldset>
        <div class="row modal-actions">
          <div class="twelve">
            <button class="button {{context.okBtnClass}}" (click)="submitUserInput()"
              [class.disabled]="userInputValue?.length < validLength"
              [disabled]="userInputValue?.length < validLength">{{context.okText}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</div>
