<div class="modal">
<div class="container model-container {{context.customClass}}" (keypress.esc)="onKeydown($event)">
  <div class="modal-header">
    <h2 class="modal-heading">{{context.title}}</h2>
    <button *ngIf="context.hasCloseLink" class="modal-close modal-toggle" (click)="closeDialog()"><i
        class="icon-close"></i></button>
  </div>
  <div class="modal-body">
    <div class="modal-content">
      <div class="row">
        <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
      </div>
      <div class="row modal-actions" *ngIf="!!context.primaryButton || !!context.secondaryButton">
        <button class="button primary" *ngIf="!!context.primaryButton" (click)="closeDialog(true)"
          id="modal--primary-url-button" data-cy="modal--primary-url-button">{{context.primaryButton}}</button>
        <button class="button secondary" *ngIf="!!context.secondaryButton" (click)="closeDialog(false)"
          id="modal-secondary-url-button">{{context.secondaryButton}}</button>
      </div>
    </div>
  </div>
</div>
</div>