<div class="instruction-box">
    <div class="box-header">
        <div class="close-btn" (click)="close()">
            <img src="assets/icon/close-white.svg" width="20px" height="20px">
        </div>
        <h5 class="box-title">How to download your iPhone LTE settings</h5>
    </div>
    <div class="box-body" [ngSwitch]="activeStep" [@animImageSlider]="activeStep">
        <div *ngSwitchCase="1">
            <div class="description">
                <p class="smaller">1. Click on the “Download File” button as highlighted below.</p>
            </div>
            <div class="image">
                <img class="download-file-image" src="assets/img/download-file.svg" width="76px" height="74px">
                <img src="assets/img/iphone-4g-step-1.svg" width="129px" height="270px">
            </div>
        </div>
        <div *ngSwitchCase="2">
            <div class="description">
                <p class="smaller">2. After approving the configuration profile to download, a file will be downloaded
                    to your iPhone device.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone-4g-step-2.svg" width="129px" height="270px">
            </div>
        </div>
        <div *ngSwitchCase="3">
            <div class="description">
                <p class="smaller">3. Go to your iPhone’s Settings and Click on ‘General’.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone-4g-step-3.svg" width="129px" height="270px">
            </div>
        </div>
        <div *ngSwitchCase="4">
            <div class="description">
                <p class="smaller">4. Go to ‘Profile’ from your General Settings.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone-4g-step-4.svg" width="129px" height="270px">
            </div>
        </div>
        <div *ngSwitchCase="5">
            <div class="description">
                <p class="smaller">5. Next, click on the good2GO LTE Data Settings tab to configure your iPhone LTE
                    settings.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone-4g-step-5.svg" width="129px" height="270px">
            </div>
        </div>
        <div *ngSwitchCase="6">
            <div class="description">
                <p class="smaller">6. Click on ‘Install’ to install the LTE iPhone configuration settings on your
                    device.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone-4g-step-6.svg" width="129px" height="270px">
            </div>
        </div>
        <div *ngSwitchCase="7">
            <div class="description">
                <p class="smaller">7. You will be asked for your device password.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone-4g-step-7.svg" width="129px" height="270px">
            </div>
        </div>
        <div *ngSwitchCase="8">
            <div class="description">
                <p class="smaller">8. Click ‘Install’ on the top right.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone-4g-step-8.svg" width="129px" height="270px">
            </div>
        </div>
        <div *ngSwitchCase="9">
            <div class="description">
                <p class="smaller">9. Click ‘Install’ again to confirm.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone-4g-step-9.svg" width="129px" height="270px">
            </div>
        </div>
        <div *ngSwitchCase="10">
            <div class="description">
                <p class="smaller">10. You’re done! The iPhone LTE Data Settings have been successfully downloaded to
                    your device, you can now enjoy your Good2Go service.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone-4g-step-10.svg" width="129px" height="270px">
            </div>
        </div>
    </div>
    <div class="box-footer">
        <button class="button secondary" (click)="back()" *ngIf="this.activeStep > 1">Back</button>
        <button class="button primary" (click)="next()" [disabled]="this.activeStep == 10">Next</button>
    </div>
</div>