import { Component, OnInit } from '@angular/core';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { MatDialogRef } from '@angular/material/dialog';


const back = [
  group([
    query(':enter', [style({ transform: 'translateX(-100%)', 'text-wrap': 'nowrap' }), animate('.3s ease-in', style({ transform: 'translateX(0)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0)', 'text-wrap': 'nowrap' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
  ]),
];

const next = [
  group([
    query(':enter', [style({ transform: 'translateX(100%)', 'text-wrap': 'nowrap' }), animate('.3s ease-in', style({ transform: 'translateX(0)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0)', 'text-wrap': 'nowrap' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
  ]),
];

@Component({
  selector: 'app-instractions-four-g-modal',
  templateUrl: './instractions-four-g-modal.component.html',
  styleUrls: ['./instractions-four-g-modal.component.scss'],
  animations: [trigger('animImageSlider', [
    transition(':increment', next),
    transition(':decrement', back),
  ])]
})
export class InstractionsFourGModalComponent implements OnInit {

  constructor(private dialog: MatDialogRef<InstractionsFourGModalComponent>) {
    this.activeStep = 1;
  }

  activeStep: number;

  ngOnInit(): void {
  }

  next() {
    if (this.activeStep == 10)
      return;
    ++this.activeStep;
  }

  back() {
    if (this.activeStep == 1)
      return;
    --this.activeStep;
  }

  close() {
    this.dialog.close();
  }
}
