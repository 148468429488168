import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalHelperService } from 'src/services/modal-helper.service';
import { PlatformLocation } from '@angular/common';
import { AppState } from '@app/app.service';
import { Subscription } from 'rxjs';

export class eSIMReplacementModalContext {
  public iccid: string;
  public mdn: string;
  public customClass: string;
}
@Component({
  selector: 'app-esim-replacement-modal',
  templateUrl: './esim-replacement-modal.component.html'
})
export class eSimReplacementModalComponent implements OnDestroy {
  public context: any;
  public captchaResponse;
  public recaptchaResolvedSubscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<eSIMReplacementModalContext>, private modalHelper: ModalHelperService, private location: PlatformLocation,
  private appState: AppState) {
    location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
    this.context = data;

  }

  ngOnDestroy(): void {
    if (this.recaptchaResolvedSubscription) {
      this.recaptchaResolvedSubscription.unsubscribe();
    }
  }
  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }
  closeDialog(): void {
    this.beforeDismiss();
    this.dialog.close(false);
  }

  confirm(): void {
    const action = 'esim-replacement';
    this.appState.resetAndExecuteCaptchaSubject.next({action});
    this.recaptchaResolvedSubscription= this.appState.resolvedCaptcha.subscribe(response => {
      this.captchaResponse = response;
      if (!!this.captchaResponse) {
        this.recaptchaResolvedSubscription?.unsubscribe();
        this.dialog.close(this.captchaResponse);
      }
    });

  }
}
