import { CUSTOM_ELEMENTS_SCHEMA, EnvironmentProviders, ErrorHandler, ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { AngularFireModule } from '@angular/fire/compat';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppState } from './app.service';
import { ModalsModule } from '../modals/modals.module';
import { SwiperModule } from 'swiper/angular';
import { ENDPOINT_URL, ENV_FIREBASE_CONFIG, BFF_ENDPOINT_URL, ZMP_G2G_BFF_ENDPOINT_URL, GOOGLE_CLIENT_ID, environment } from '../environments/environment';
import { AuthModule, ErrorHandlerService } from '@ztarmobile/zwp-services-auth';
import { Metadata, ZwpConfig, CoreModule } from '@ztarmobile/zwp-service';
import { UtilModule } from '@ztarmobile/zwp-services-util';
import { BackendModule } from '@ztarmobile/zwp-service-backend';
import { BackendV2Module } from '@ztarmobile/zwp-service-backend-v2';
import { ToastrModule } from 'ngx-toastr';
import 'leaflet';
import 'leaflet.vectorgrid';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { HomeComponent } from './home/home.component';
import { TelecomsServicesModule } from '../providers/providers';
import { WidgetsModule } from '../widgets/widgets.module';
import { MetaService } from '../services/meta-service.service';
import { EbbManager } from '../services/ebb.service';
import { ContentfulService } from '../services/contentful.service';
import { ToastrHelperService } from '../services/toast-helper.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { BringPhoneComponent } from './bring-phone/bring-phone.component';
import { HdVoiceComponent } from './hd-voice/hd-voice.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { WifiCallingComponent } from './wifi-calling/wifi-calling.component';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';
import { PhoneManagementService } from 'src/services/phones.service';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { initializeApp } from 'firebase/app';
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './http-interceptor';
import { AnalyticsService } from '@services/analytics.service';

export const metadata: Metadata = {
  author: 'ZtarMobile',
  keywords: 'Good2Go Mobile, Cheap Mobile Plans, 5G network, Nationwide 5G Coverage, Two networks, GOVERNMENT BENEFIT PROGRAM, ACP, refurbished iPhones, Free plan, Cheap iphones',
  description: 'Looking for a cheap cell phone plan? Choose Good2Go Mobile using the largest and fastest 5G network with no long-term commitment, no credit check. Bring your own phone'
};
const zwpConfig: ZwpConfig = {
  endpointUrl: `${ENDPOINT_URL || ''}`,
  BffEndPointURL: `${BFF_ENDPOINT_URL}`,
  ZMP_G2G_BFF_ENDPOINT_URL: `${ZMP_G2G_BFF_ENDPOINT_URL}`,
  consoleErrorReporting: true,
  firebaseAppConfig: ENV_FIREBASE_CONFIG,
  metadata
};

const app = initializeApp(ENV_FIREBASE_CONFIG);
const googleLoginOptions: Object = {
  scope: 'profile email'
};
const isProduction = environment.production;

const providers: (Provider | EnvironmentProviders)[] = [AppState, MetaService, ContentfulService, ToastrHelperService, ModalHelperService, EbbManager, PhoneManagementService, AnalyticsService,
  { provide: ErrorHandler, useClass: ErrorHandlerService }];
if (!isProduction) {
  providers.push(
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            GOOGLE_CLIENT_ID, googleLoginOptions
          )
        }
      ],
      onError: (error) => {
        console.error(error);
      }
    } as SocialAuthServiceConfig
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  })
}
const imports: (any[] | Type<any> | ModuleWithProviders<{}>)[] = [ AngularFireModule.initializeApp(ENV_FIREBASE_CONFIG),
  AngularFireAuthModule,
  AngularFireDatabaseModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  BrowserModule,
  BrowserAnimationsModule,
  TelecomsServicesModule,
  ShareButtonModule,
  NgxPageScrollCoreModule.forRoot({ duration: 200 }),
  ToastrModule.forRoot(),
  CoreModule.forRoot(zwpConfig),
  AuthModule,
  UtilModule,
  BackendV2Module,
  BackendModule,
  WidgetsModule,
  ModalsModule,
  AppRoutingModule,
  MatDialogModule,
  SwiperModule,
  MatAutocompleteModule];
  if (!isProduction) {
    imports.push(SocialLoginModule,GoogleSigninButtonModule);
  }
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BringPhoneComponent,
    HdVoiceComponent,
    NotFoundComponent,
    WifiCallingComponent,
    ReferFriendComponent
  ],
  imports: imports,
  exports: [],
  providers: providers,
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
