import { PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FuturePayService } from '@ztarmobile/zwp-service-backend-v2';

export class FuturePayProcessModalContext {
}
/**
 * PT_MESSAGE
 * Wrapper for messages returned from the PayTomorrow IFrame as a PostMessage to communicate with the parent site
 * PT_CLOSE: "pt-close"
 * @returned when user clicks X from the IFrame.
 * PT_ERROR: "pt-error"
 * @returned when there is an error in the application or if the application is declined.
 * PT_FINISHED: "pt-finished"
 * @returned on the Final Step when the user clicks close button and his application was approved.
 * PT_CONFIRMATION: "pt-confirmation"
 * @returned on the Final Step when the application is ready to be sent but the status of the application is not confirmed yet
 * Unhandled PT messages:
 * PT_REACAPTCHA: 'recaptcha-setup'
 * PT_INSPIHA: 'inspiha'
*/
export const PT_MESSAGE = {
  PT_CLOSE: "pt-close",
  PT_ERROR: "pt-error",
  PT_FINISHED: "pt-finished",
  PT_CONFIRMATION: "pt-confirmation",
  // Unhandled PT messages:
  PT_REACAPTCHA: 'recaptcha-setup',
  PT_INSPIHA: 'inspiha'
};

@Component({
  selector: 'app-future-pay-process',
  templateUrl: './future-pay-process.component.html',
  styleUrls: ['./future-pay-process.component.scss']
})
export class FuturePayProcessComponent {
  public context: any;
  public isUrlReady = false;
  public processingRequest = false;
  public infoComplete = false;
  public hasErrors = false;

  public iFrameUrl;
  public applicationId = '';
  public customErrorMsg = '';
  public ptForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialogRef<FuturePayProcessModalContext>,
    private location: PlatformLocation,
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private futurePayService: FuturePayService) {
    location.onPopState(() => { this.beforeDismiss(); this.dialog.close(); });
    this.context = {};
    this.context.shippingDetails = data;
    this.dialog.disableClose = true;
    //TODO fix validator after testing phase is done for last name to not allow numeric values
    this.ptForm = new FormGroup({
      firstName: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z\s]*$/)])),
      lastName: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)])),
      phoneNumber: new FormControl('', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[1-9][0-9]*$/)])),
    });
  }

  public getPtIframeUrl() {
    this.processingRequest = true;
    const applcationSetup = {
      customer: {
        firstName: this.ptForm.controls.firstName.value,
        lastName: this.ptForm.controls.lastName.value,
        phoneNumber: this.ptForm.controls.phoneNumber.value
      },
      shippingAddress: this.context.shippingDetails.shippingAddress,
      shippingMethodId: this.context.shippingDetails.shippingMethodId,
      paymentInfo: {
        city: this.context.shippingDetails.city,
        state: this.context.shippingDetails.state,
        postalCode: this.context.shippingDetails.postalCode
      }
    };

    // paymentInfo is to calculate taxes based on the CC not based on the shipping address
    if (!applcationSetup.paymentInfo.city ||
      !applcationSetup.paymentInfo.state ||
      !applcationSetup.paymentInfo.postalCode) {
      delete applcationSetup['paymentInfo'];
    }

    this.futurePayService.startApplication(applcationSetup).then((res) => {
      if (!!res) {
        console.log('startApplication', res);
        this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.url);
        this.applicationId = res.applicationId;
        this.isUrlReady = true;
        this.addEventListeners()
        this.cdRef.detectChanges();
      }
    }).finally(() => {
      this.processingRequest = false;
    }).catch((error) => {
      if (!!error && !!error?.error && !!error?.error?.errors && error?.error?.errors?.length > 0) {
        if (error?.error?.errors[0]?.code === 102) {
          this.customErrorMsg = '';
          error?.error?.errors[0].params.forEach((e) => {
            this.customErrorMsg += `<p class="validation-message" >${e.message} for ${e.param_name.split('.')[1]}</p>`
          });
        }
      }

      this.processingRequest = false;
      this.infoComplete = false;
      this.hasErrors = true;
    });

  }

  public next() {
    if (this.ptForm.valid) {
      this.infoComplete = true;
      this.getPtIframeUrl();
    }
  }

  public goToPayments(): void {
    this.closeDialog({ applicationId: null, isProcessComplete: false });
  }

  public receiveMessage(event) {
    console.log('receiveMessage from PT Iframe', event);
    switch (event.data) {
      case PT_MESSAGE.PT_ERROR:
        this.closeWithDelay(false, 6000);
        break;
      case PT_MESSAGE.PT_CLOSE:
        this.closeWithDelay(false, 0);
        break;
      case PT_MESSAGE.PT_FINISHED:
        this.closeWithDelay(true, 0);
        break;
    }
  }

  public closeWithDelay(isComplete, delay) {
    setTimeout(() => {
      this.closeDialog({ applicationId: this.applicationId, isProcessComplete: isComplete });
    }, delay);
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  closeDialog(res?): void {
    this.beforeDismiss();
    this.dialog.close(res);
  }

  private addEventListeners() {
    window.addEventListener("message", (event) => {
      this.receiveMessage(event);
    }, false);
  }



}
