<div class="modal">
<div class="container model-container full referral-promo-modal">
    <div class="modal-header">
        <button class="modal-close modal-toggle" (click)="skipPromo()"><i class="icon-close"></i></button>
    </div>
    <div class="modal-body">
        <div class="modal-content">
           <div class="content">
               <div class="image-section">
                    <picture>
                        <source width="300" srcset='assets/img/Mobile-Spring-Image.webp' media="(max-width: 739.9.9px)" type="image/webp">
                        <source width="300" srcset='assets/img/Mobile-Spring-Image.jpg' media="(max-width: 739.9px)" type="image/jpg">
                        <source width="370" height="531" srcset='assets/img/Web-Spring-Image.webp' media="(min-width: 741px)" type="image/webp">
                        <source width="370" height="531" srcset='assets/img/Web-Spring-Image.jpg' media="(min-width: 741px)" type="image/jpg">
                        <img src="assets/img/Web-Spring-Image.jpg" alt="Refer A Friend Image"/>
                  </picture>
               </div>
               <div class="info-section">
                   <h2 class="title">GET REWARDS FOR EVERY FRIEND YOU REFER. </h2>
                   <p class="description">Your friends get $10 and you get $10 after their first purchase.</p>
                   <button class="button primary" aria-label="Start sharing" (click)="goToReferFriend()">Start sharing</button>
                   <p class="skip" (click)="skipPromo()">Skip for now</p>
                   <p class="terms" (click)="goToTerms()">Read the Terms</p>
               </div>
           </div>
        </div>
    </div>
</div>
</div>