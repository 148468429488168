import { Component, OnInit } from '@angular/core';
import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { ContentfulService } from 'src/services/contentful.service';
import { ACCOUNT_ROUTE_URLS, LOGIN_ROUTE_URLS, SUPPORT_ROUTE_URLS } from '../app.routes.names';
import { AppState } from '@app/app.service';

@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.scss']
})
export class ReferFriendComponent implements OnInit {
  public LOGIN_ROUTE_URLS = LOGIN_ROUTE_URLS;
  public ACCOUNT_ROUTE_URLS = ACCOUNT_ROUTE_URLS;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;
  public firstThreeQuestions;
  public restQuestions;
  public questionIdParam: any;
  public faqLD;
  public collapsed: boolean;
  public showMore = false;
  public isLoggedIn = true;

  constructor(private contentfulService: ContentfulService,
    private appState: AppState,
    private simpleAuthService: SimpleAuthService) { }

  ngOnInit(): void {
    this.contentfulService.getQuestionsByCategoryId('good2goFaqs', 'refer-a-friend').subscribe(async questions => {
      if (!!questions) {
        const allQuestions = questions[0].fields.questions;
        this.faqLD = await this.appState.setupFAQsJsonLd(questions);
        this.firstThreeQuestions = allQuestions.slice(0, 3);
        this.restQuestions = allQuestions.slice(3);
      }
    });
    this.simpleAuthService.userState.subscribe((authState) => {
      this.isLoggedIn = !!authState && !authState.isAnonymous;
    });
  }
  public toggleActive(questionId, answerId): void {
    if (this.questionIdParam === questionId && !this.collapsed) {
      this.questionIdParam = 'q0';
    } else {
      this.questionIdParam = questionId;
      this.collapsed = false;
      this.callRichText(answerId);
    }
  }
  private callRichText(answerId): void {
    this.contentfulService.getRichText('questions', answerId);
  }
}
