import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { UserAuthService } from '@ztarmobile/zwp-services-auth';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserAccountService } from '@ztarmobile/zwp-service-backend';
import { ToastrHelperService } from '../../services/toast-helper.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { INVISIBLE_CAPTCHA_ID } from 'src/environments/environment';
import { PlatformLocation } from '@angular/common';
import { getAuth, PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from 'firebase/auth';
import { AppState } from '@app/app.service';
import { Subscription } from 'rxjs';
import { ModalHelperService } from '@services/modal-helper.service';
import { CAPTCHA_BOT_ERROR_CODE } from '@app/app.config';

export class ActivatedNumberModalContext {
  public customClass?: string;
  public label?: string;
  public title?: string;
}

@Component({
  selector: 'app-add-activated-number-modal',
  templateUrl: './add-activated-number-modal.component.html'
})
export class AddActivatedNumberModalComponent implements OnDestroy {
  public context: any;
  public phoneNumber: string;
  public verificationCode: string;
  public waitingConfirmCode = false;
  public processingRequest = false;
  public processingMdnRequest = false;
  public recaptchaResolvedSubscription: Subscription;

  private verificationId: string;
  private recaptchaVerifier;
  private invisibleCaptchaRes;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<ActivatedNumberModalContext>,
    private userAuthService: UserAuthService,
    private toastHelper: ToastrHelperService,
    private angularAuthService: AngularFireAuth,
    private userAccountService: UserAccountService,
    private location: PlatformLocation,
    private appState: AppState, private modalHelper: ModalHelperService) {
    location.onPopState(() => { this.beforeDismiss(); this.dialog.close(); });
    this.context = data;
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.recaptchaResolvedSubscription) {
      this.recaptchaResolvedSubscription.unsubscribe();
    }
  }
  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  cancel(): void {
    this.beforeDismiss();
    this.dialog.close();
  }

  public sendCode(): void {
    const action = 'g2g_validate_mdn';
    this.appState.resetAndExecuteCaptchaSubject.next({action});
    if (!!this.phoneNumber && this.phoneNumber?.length > 8) {
      this.appState.loading = true;
      this.processingMdnRequest = true;
      this.recaptchaResolvedSubscription= this.appState.resolvedCaptcha.subscribe(response => {
      this.invisibleCaptchaRes = response;
      if(!!this.invisibleCaptchaRes) {
        this.recaptchaResolvedSubscription?.unsubscribe();
        this.callIsValidPhoneNumber(this.invisibleCaptchaRes, true, action);
      }
     });
    } else {
      this.toastHelper.showAlert('Please enter valid phone number');
    }
  }

  public callIsValidPhoneNumber(captchaRes, invisibleCaptchaRes, action?): void {
    this.userAccountService.isValidPhoneNumber(this.phoneNumber, captchaRes, !!action?action:null, invisibleCaptchaRes).then(() => {
      this.sendVerificationCode();
    }, (error) => {
      this.processingMdnRequest = false;
      this.appState.loading = false;
      if(error?.code === CAPTCHA_BOT_ERROR_CODE) {
        this.modalHelper.showRecaptchaVerificationModal().afterClosed().subscribe((result)=>{
          if(!!result) {
            this.appState.loading = true;
            this.callIsValidPhoneNumber(result,false);
          }
        })
       } else {
        this.toastHelper.showAlert(error.message);
       }
      this.recaptchaResolvedSubscription?.unsubscribe();
    });
  }
  public verifyLoginCode(): void {
    this.processingRequest = true;
    const auth = getAuth();
    const phoneAuthProvider = PhoneAuthProvider;
    const cred = phoneAuthProvider.credential(this.verificationId, this.verificationCode);
    updatePhoneNumber(auth.currentUser, cred).then(() => {
      this.processingRequest = false;
      this.dialog.close(this.phoneNumber);
    }).catch((error) => {
      this.processingRequest = false;
      this.toastHelper.showAlert(error.message);
    });
  }

  private sendVerificationCode(): void {
    const auth = getAuth();
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    //clear captcha container 
    const recaptchaContainer = document.getElementById('recaptchaContainer');
    recaptchaContainer.innerHTML = '';
    setTimeout(() => {
      this.recaptchaVerifier = new RecaptchaVerifier('recaptchaContainer', {
        size: 'invisible',
        callback: () => {
        }
      }, auth);
      phoneAuthProvider.verifyPhoneNumber(`+1${this.phoneNumber}`, this.recaptchaVerifier).then((result) => {
        this.verificationId = result;
        this.waitingConfirmCode = !!result;
        this.processingMdnRequest = false;
        this.appState.loading = false;
      }).catch((error) => {
        this.processingMdnRequest = false;
        this.appState.loading = false;
        this.toastHelper.showAlert(error.message);
      });
    }, 1000);
  }
}

