import { PlatformLocation } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppState } from 'src/app/app.service';
import { ModalHelperService } from 'src/services/modal-helper.service';

@Component({
  selector: 'app-canadian-location',
  templateUrl: './canadian-location.component.html',
  styleUrls: ['./canadian-location.component.scss']
})
export class CanadianLocationComponent implements OnDestroy {

  constructor(public dialog: MatDialogRef<any>, private location: PlatformLocation,
    private modalHelper: ModalHelperService, private appState: AppState) {
  }

  ngOnDestroy(): void {
    this.appState.callInternalChecks.next(false);
  }
  public goToCanadianWebsite(): void {
    window.location.href = 'https://good2gomobile.ca/default.aspx';
  }
  public closeDialog(res?:boolean): void {
    if(!!res) {
      this.appState.callInternalChecks.next(true);
    } else {
      this.appState.callInternalChecks.next(false);
    }
    this.dialog.close();
  }
  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: any): void {
    if (event.keyCode === 27) {
      event.preventDefault();
      if (document.querySelector('body div.canadian-modal')) {
        this.closeDialog();
        this.modalHelper.showCanadianLocationModal();
      }
    }
  }
}
