import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ACCOUNT_ROUTE_URLS, SUPPORT_ROUTE_URLS } from 'src/app/app.routes.names';
import { PlatformLocation } from '@angular/common';

export class ReferralPromotionContext {
  public customClass?: string;
}

@Component({
  selector: 'app-referral-promotion',
  templateUrl: './referral-promotion.component.html'
})
export class ReferralPromotionComponent {
  public context: any;
  public index = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<ReferralPromotionContext>, private router: Router, private location: PlatformLocation) {
    sessionStorage.setItem('hideReferral', 'hide');
    location.onPopState(() => { this.beforeDismiss();this.dialog.close();});
    this.context = data;
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

 closeDialog(result?: any): void {
    sessionStorage.setItem('hideReferral', 'hide');
    this.beforeDismiss();
    this.dialog.close(result);
  }

  public goToReferFriend(): void {
    this.closeDialog();
    this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.REFER_FRIEND}`]);
  }

  public goToTerms(): void {
    this.router.navigate([`${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS}/${SUPPORT_ROUTE_URLS.REFER_A_FRIEND}`]);
    this.closeDialog();
  }

  public skipPromo(): void {
    this.closeDialog();
  }
}
