import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentfulService } from '../../services/contentful.service';
import { PlatformLocation } from '@angular/common';

export class DynamicModalContext {
  public message: string;
  public title: string;
  public btnText: string;
  public btnUrl?: string;
  public hasCloseLink?: boolean;
  public customClass?: string;
  public customHTML?: string;
  public contentfulModel?: string;
  public renderElementId?: string;
  public richTextId?: string;
  public cancelBtn?: boolean;
  public cancelText?: string;
  public noteText?: string;
}
@Component({
  selector: 'app-dynamic-modals',
  templateUrl: './dynamic-modals.component.html'
})
export class DynamicModalsComponent{
  public context: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<DynamicModalContext>, private contentFulService: ContentfulService,
              private location: PlatformLocation) {
    location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
    this.context = data;
    if ( !!this.context.richTextId && !!this.context.contentfulModel && !!this.context.renderElementId) {
      this.contentFulService.getPopUpDescriptions(this.context.contentfulModel, this.context.renderElementId, this.context.richTextId);
    }
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  closeDialog(res?): void {
    this.beforeDismiss();
    this.dialog.close(res);
  }

  OK(): void {
    this.closeDialog(true);
  }
  cancel(): void{
    this.closeDialog(false);
  }
}

