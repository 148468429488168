<div class="modal">
    <div class="container model-container {{context.customClass}}">
        <img src="assets/img/security.svg" alt="security-icon">
        <div class="modal-header">
            <h2 class="modal-heading">{{context.title}}</h2>
        </div>
        <div class="modal-body">
                <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
                <div class="note" *ngIf="!!context.timer">
                    <p class="details">{{minutes}} : {{seconds}} minutes remaining</p>
                </div>
                <div class="modal-actions">
                    <div class="left" *ngIf="!!context.primaryBtn">
                        <button class="button primary" (click)="OK()" id="action-button"
                            data-cy="action-button">{{context.primaryBtn}}</button>
                    </div>
                    <div class="right" *ngIf="!!context.secondaryBtn">
                        <button class="button secondary" (click)="cancel()" id="action-button"
                            data-cy="action-button">{{context.secondaryBtn}}</button>
                    </div>
                </div>
        </div>
    </div>
</div>