import { PlatformLocation } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IUserPlan, UserPlansService } from '@ztarmobile/zwp-service-backend';
import { ACCOUNT_ROUTE_URLS, MIGRATION_ROUTE_URLS } from 'src/app/app.routes.names';
import { AppState } from 'src/app/app.service';
import { ToastrHelperService } from 'src/services/toast-helper.service';

export class  MigrationVerifiedFlowContext {
  public title: string;
  public customClass?: string;
  public hasCloseLink?: boolean;
  public selectedPlan?: IUserPlan;
  public phoneImei?: string;
  public planMdn?: string;
  public selectedPlanId?: string;
  public activationCode?:string
}

@Component({
  selector: 'app-migration-verified-flow',
  templateUrl: './migration-verified-flow.component.html'
})
export class MigrationVerifiedFlowComponent{
  public context: any;
  public MIGRATION_ROUTE_URLS = MIGRATION_ROUTE_URLS;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<MigrationVerifiedFlowContext>, private location: PlatformLocation,
  private userPlansService: UserPlansService, private toastHelper: ToastrHelperService, private router: Router, private appState: AppState) {
    location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
    this.context = data;
  }
  onKeydown(event) {
    event.stopImmediatePropagation();
  }
  beforeDismiss(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }
  public closeDialog(result?): void {
    this.beforeDismiss();
    this.dialog.close(result);
  }
  public startMigrationService(): void {
    let code = '';
      code = !!this.context.selectedPlan.eSimDetails ? this.context.selectedPlan.eSimDetails.activationCode: this.context?.activationCode;
      code = code.trim();
      this.callMigrationService(code);
  }
  private callMigrationService(code): void {
    this.appState.loading = true;
    this.userPlansService.submitMigrationRequest(this.context.planMdn, {activationCode: code}).then((result) => {
      this.toastHelper.showSuccess('Migration Request Submitted Successfully!');
      this.appState.loading  = false;
      this.userPlansService.getUserPlan(this.context.selectedPlanId).then((plan) => {
        plan.planDevice.id = this.context.phoneImei;
        plan.planDevice.pendingMigrationSim = false;
        delete plan.migrationDevice;
        this.userPlansService.updateUserPlan(plan.userId, plan).then(() => {
          this.userPlansService.selectUserPlan(this.context.selectedPlanId);
          this.closeDialog();
          this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.SUMMARY}`]);
        }, (error) => {
          this.toastHelper.showAlert(error.message || error.error ? error.error.message : error);
        });
      });
    }, (error) => {
      this.appState.loading = false;
      this.toastHelper.showAlert(error.message);
    });
  }
}
