<div class="modal">
    <div class="container model-container futurePay-modal">
        <div class="modal-header">
            <h2 class="modal-heading">PayTomorrow™ Application Process</h2>
        </div>
        <div class="modal-body">
            <div class="form-section pt-form" *ngIf="!infoComplete">
                <form [formGroup]="ptForm" class="ptForm">
                    <div class="form-header">
                        <span (click)="goToPayments()" class="action-icon icon close-action"><img
                                src="/assets/icon/Cancel iconwhite.svg"></span>
                    </div>
                    <fieldset>
                        <div>
                            <p>
                                Enter your legal name and your phone number to proceed:
                            </p>
                        </div>
                        <div class="field-set-label">
                            <b>Your Name:</b>
                            <p>The name you use on official documents, like your Social Security Card. </p>
                        </div>
                        <div class="name-section">
                            <!-- <label for="name">First Name <span class="required-field">*</span></label> -->
                            <input class="nameField" type="text" formControlName="firstName" minlength="1"
                                maxlength="30" placeholder="First Name">
                            <p class="validation-message"
                                *ngIf="ptForm.controls.firstName.touched && ptForm.controls.firstName.hasError('required')">
                                Your first name is required</p>
                            <p class="validation-message"
                                *ngIf="ptForm.controls.firstName.dirty && ptForm.controls.firstName.errors?.pattern && ptForm.controls.firstName.value">
                                Invalid name </p>
                        </div>
                        <div class="name-section">
                            <!-- <label for="name">Last Name <span class="required-field">*</span></label> -->
                            <input class="nameField" type="text" formControlName="lastName" minlength="1" maxlength="30"
                                placeholder="Last Name">
                            <p class="validation-message"
                                *ngIf="ptForm.controls.lastName.touched && ptForm.controls.lastName.hasError('required')">
                                Your last name is required </p>
                            <p class="validation-message"
                                *ngIf="ptForm.controls.lastName.dirty && ptForm.controls.lastName.errors?.pattern && ptForm.controls.lastName.value">
                                Invalid name </p>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div class="field-set-label">
                            <b>
                                Your Phone Number:
                            </b>
                        </div>
                        <div class="name-section last">
                            <!-- <label for="name">Phone Number <span class="required-field">*</span></label> -->
                            <input class="nameField" type="text" formControlName="phoneNumber" minlength="1"
                                maxlength="10" placeholder="Phone Number">
                            <p class="validation-message"
                                *ngIf="ptForm.controls.phoneNumber.touched && ptForm.controls.phoneNumber.hasError('required')">
                                Your phone number is required</p>
                            <p class="validation-message"
                                *ngIf="ptForm.controls.phoneNumber.hasError('pattern') || ptForm.controls.phoneNumber.hasError('minlength') || ptForm.controls.phoneNumber.hasError('maxlength')">
                                Invalid phone number </p>

                            <ngContainer *ngIf="!!customErrorMsg && customErrorMsg!= ''" [innerHtml]="customErrorMsg">
                            </ngContainer>
                        </div>
                    </fieldset>

                    <button class="button primary" data-cy="updatePaymentBtn" (click)="next()"
                        [uiBlockButton]="processingRequest">
                        Next
                    </button>
                </form>

                <div class="pt-form-footer">
                    <img src="/assets/img/pt_process_start.svg">
                </div>
            </div>
            <div class="iframe-section" *ngIf="!!iFrameUrl" [class.loading]="!isUrlReady">
                <div class="pt-iframe-container" *ngIf="!!isUrlReady && !!iFrameUrl">
                    <iframe id="pt-iframe" class="pt-iframe" [src]="iFrameUrl" (receiveMessage)="$event" frameborder="0"
                        width="600" height="915"></iframe>
                </div>
            </div>
            <div *ngIf="processingRequest || infoComplete && !isUrlReady">
                <div class="loading-container">
                    <img src="/assets/img/loading-clock.gif" />
                    <p>PayTomorrow™ application {{(infoComplete && !isUrlReady)?'loading':'is being processed'}}</p>
                </div>
            </div>
        </div>
    </div>
</div>