<div class="modal">
<div class="container model-container {{context.customClass}}">
    <div class="modal-header">
      <h2 class="modal-heading">{{context.title}}</h2>
      <button *ngIf="context.hasCloseLink" class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <div class="main-content">
          <div id="terms-content">
            <p class="main-title"><strong>Welcome to Good2GO Mobile. In order to present promotional prices, please read and accept conditions below:</strong></p>
            <p class="first">With Good2Go Mobile you get everything you need at a fraction of the usual cost</p>
            <ul class="features-list">
                <li class="feature">Real 4G speeds right out of the gate, powered by America’s fastest network </li>
                <li class="feature">FREE SIM card; FREE ACTIVATION</li>
                <li class="feature">Unlimited talk and text and the ability to add more high-speed cell data whenever you want</li>
            </ul>
            <p class="details">{{context.message1}}</p>
            <p class="requirements">{{context.message2}}</p>
            <p class="terms"><span class="temsApplies">Normal <a class="termsLink" (click)="goToTerms()">Terms & Conditions apply</a>.</span>
                <span class="action"><input type="checkbox" name="agreeWithTerms" class="termsCheckbox" [(ngModel)]="isAgreed" /> I agree to the Terms and Conditions</span></p>
          </div>
        </div>
        <div class="row modal-actions">
            <button class="button primary" [disabled]="!isAgreed" (click)="proceed()">Proceed with promo</button>
        </div>
      </div>
    </div>
  </div>
</div>
  