<footer>
    <div class="main-links">
        <div class="all-main-links">
            <div class="links-group">
                <div class="title" (click)="toggleMenu('Shop')">
                    <h5>Shop</h5>
                    <picture>
                        <img src="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='Shop'">
                        <img src="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='Shop'">
                    </picture>
                </div>
                <div class="links" [ngClass]="{'opened': openedMenu=='Shop'}">
                    <a data-cy="cellPhonePlans"
                        [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
                        class="button tertiary">Cell Phone Plans</a>
                    <a data-cy="cellPhones"
                        [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]"
                        class="button tertiary">Cell Phones</a>
                </div>
            </div>
            <div class="links-group">
                <div class="title" (click)="toggleMenu('Account')">
                    <h5>Account</h5>
                    <picture>
                        <img src="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='Account'">
                        <img src="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='Account'">
                    </picture>
                </div>
                <div class="links" [ngClass]="{'opened': openedMenu=='Account'}">
                    <a data-cy="accountSummary" class="button tertiary"
                        [routerLink]="[ACCOUNT_ROUTE_URLS.BASE +'/'+ ACCOUNT_ROUTE_URLS.SUMMARY]">Account Summary</a>
    
                    <a data-cy="payAndRefill" class="button tertiary"
                        [routerLink]="[ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.PAY_AND_RENEW]">Pay & Refill your
                        Account</a>
                    <a data-cy="yourOrders" class="button tertiary"
                        [routerLink]="[ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.ORDERS]">Your
                        Orders</a>
                    <a data-cy="paymentrHistory" class="button tertiary"
                        [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.PAYMENTS]">Payment History</a>
    
                    <a data-cy="usage" class="button tertiary"
                        [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.USAGE]">Usage</a>
    
                    <a data-cy="manageDevices" class="button tertiary"
                        [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.MANAGE_DEVICES]">Manage
                        Devices</a>
                    <a *ngIf="!!isLoggedIn && !!displayAcpSection" data-cy="acpApplication" class="button tertiary"
                        [routerLink]="[ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.ACP_APPLICATION]">ACP
                        Application</a>
                    <a data-cy="profileSettings" class="button tertiary"
                        [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE +'/'+ACCOUNT_ROUTE_URLS.SETTINGS]">Profile Settings</a>
                </div>
            </div>
            <div class="links-group">
                <div class="title" (click)="toggleMenu('Support')">
                    <h5>Support</h5>
                    <picture>
                        <img src="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='Support'">
                        <img src="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='Support'">
                    </picture>
                </div>
                <div class="links" [ngClass]="{'opened': openedMenu=='Support'}">
                    <a data-cy="wifiCalling" class="button tertiary" [routerLink]="[ROUTE_URLS.WIFI_CALLING]">WiFi
                        Calling</a>
                    <a data-cy="setupYourPhoneData" class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.DATA_SETUP]">Setup your Phone
                        Data</a>
                    <a data-cy="hdVoice" class="button tertiary" [routerLink]="[ROUTE_URLS.HD_VOICE]">HD Voice</a>
                    <a data-cy="faqs" class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.FAQS]">FAQs</a>
                    <a data-cy="contactUs" class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.CONTACT_US]">Contact Us</a>
                    <a data-cy="sitemapLink" class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.SITEMAP]">Sitemap</a>
                    <a data-cy="sitemapLink" class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.IOS17]">iOS17</a>
                </div>
            </div>
            <div class="links-group">
                <div class="title" (click)="toggleMenu('About')">
                    <h5>About</h5>
                    <picture>
                        <img src="assets/icon/arrow-up-circled.svg" *ngIf="openedMenu=='About'">
                        <img src="assets/icon/arrow-down-circled.svg" *ngIf="openedMenu!='About'">
                    </picture>
                </div>
                <div class="links" [ngClass]="{'opened': openedMenu=='About'}">
                    <a data-cy="aboutG2g" class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.ABOUT_GOOD2GO]">About Good2Go</a>
                    <a data-cy="whyG2g" class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.WHY_GOOD2GO]">Why Good2Go</a>
                    <a data-cy="howItWorks" class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.HOW_IT_WORKS]">How It Works</a>
                    <a data-cy="intCalling" class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.INTERNATIONAL_CALLING]">Intl.
                        Calling</a>
                </div>
                <div class="links pl-0">
                    <a class="button tertiary specialTop"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS]">Terms &
                        Conditions</a>
                    <a class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.HEARING_AID_COMPATIBILITY]">Hearing
                        Aid Compatibility</a>
                </div>
            </div>
            <div class="links-group tablet">
                <div class="links pl-0">
                    <a class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS]">Terms &
                        Conditions</a>
                    <a class="button tertiary"
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE +'/'+SUPPORT_ROUTE_URLS.HEARING_AID_COMPATIBILITY]">Hearing
                        Aid Compatibility</a>
                </div>
            </div>
        </div>
        <div class="newsletter-section" id="newsletter-section">
            <a [routerLink]="[ROUTE_URLS.HOME]" id="homepage-link">
                <img src="assets/icon/footer-logo.svg" class="logo" width="185" height="73" alt="Good2Go Mobile">
            </a>
            <div class="contents-and-form">
            <div class="newsletter-contents" id="newsletter-contents">
            <h5 class="newsletter-title" id="newsletter-title">Like saving money? Thought so!</h5>
            <p class="newsletter-note" id="newsletter-note">Sign up for our newsletter to get exclusive deals.</p>
           </div> 
            <form [formGroup]="newsletterForm" class="email-section" id="newsletter-form">
                <div class="form-flex">
                    <div class="form-field">
                        <label class="outlined-label-input">
                            <input id="footer-email" type="email" formControlName="email" required
                            autocomplete="email" placeholder=" " />
                            <span>Enter your email</span>
                         </label>
                         <label class='validation-message alert' *ngIf="newsletterForm.controls.email.hasError('pattern')"
                         id="invalid-email-msg">
                         Email address is invalid.
                       </label>
                       <label class='validation-message alert'
                         *ngIf="newsletterForm.controls.email.hasError('required') && newsletterForm.controls.email.touched"
                         id="required-email-msg">
                         Email address is required.
                       </label>
                    </div>
                   <div class="actions">
                    <button class="button primary" (click)="subscribeToNewsletter()" id="subscribe-button">Subscribe</button>
                   </div>
                   <div id="recaptchaContainer">
                   <app-invisible-recaptcha (resolved)="resolvedCaptcha($event)" [siteKey]="SITE_ID" #invisibleReCaptcha>
                </app-invisible-recaptcha>
            </div>
                </div>
            </form>
        </div>
        </div>
    </div>
    <div class="description-section">
        <div>
            <div class="bottom-text">
                <span>© 2023-2024 Good2Go Mobile. All rights reserved. iPhone, Apple and the Apple logo are registered
                    trademarks of Apple Inc. Galaxy is a registered trademark of Samsung Group.</span>
            </div>
            <div class="bottom-text">
                <span>Pixel and the Android logo are registered trademarks of Google. Other company and product names
                    may be trademarks of their respective owners.</span>
            </div>
        </div>
        <div class="social-media-links">
            <a href="https://www.facebook.com/Good2GoMobile/" target="_blank" rel="noopener">
                <picture>
                    <img src="assets/icon/facebook-black.svg" alt="Facebook Logo">
                </picture>
            </a>
            <a href="https://twitter.com/Good2GoMobile" target="_blank" rel="noopener">
                <picture>
                    <img src="assets/icon/x-black.svg" alt="X Logo">
                </picture>
            </a>
            <a href="https://www.instagram.com/good2gomobileusa/" target="_blank" rel="noopener">
                <picture>
                    <img src="assets/icon/insta-black.svg" alt="Instagram Logo">
                </picture>
            </a>
        </div>
    </div>
    <div class="build">
        Build version: {{BUILD_VERSION}} - {{BUILD_DATE}} <span style="display: none"> -
            {{FIREBASE_CONF.projectId}}</span>
    </div>
</footer>