<div class="modal">
<div class="container model-container {{context.customClass}}">
    <div class="modal-header">
      <h2 class="modal-heading">{{context.title}}</h2>
      <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
    </div>
    <div class="modal-body">
      <form #confirmPasswordForm="ngForm">
        <div class="container modal-content">
          <div class="row">
            <div class="twelve">
              <div class="row">
                <div class="twelve">
                  {{context.message}}
                </div>
              </div>
              <div class="row">
                <fieldset class="twelve">
                  <label for="confirmCurrentPassword">Current Password</label>
                  <input id="confirmCurrentPassword" data-cy="confirmCurrentPassword" type="password" name="confirmCurrentPassword" [(ngModel)]="confirmCurrentPassword" #currentPassword
                    required autocomplete="current-password">
                </fieldset>
              </div>
            </div>
          </div>
          <div class="row modal-actions">
            <div class="twelve">
              <button class="button primary" [disabled]="!confirmPasswordForm.valid" (click)="submitPassword()">OK</button>
              <button class="button" (click)="closeDialog()">Cancel</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
  