import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomizableMobilePlan, MobileCustomPlansService, CART_TYPES } from '@ztarmobile/zwp-service-backend';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.service';
import { CheckoutService } from 'src/app/shop/checkout/checkout.service';
import { ACCOUNT_ROUTE_URLS, PHONES_SHOP_ROUTE_URLS, SHOP_ROUTE_URLS } from 'src/app/app.routes.names';
import { PlatformLocation } from '@angular/common';
import { AnalyticsService } from '@services/analytics.service';

export class OutOfStockItemModalContext {
  public title: string;
  public customHTML: string;
  public customClass?: string;
  public hasCloseLink?: boolean;
  public currentPlan: CustomizableMobilePlan;
}
@Component({
  selector: 'app-out-of-stock-item-modal',
  templateUrl: './out-of-stock-item-modal.component.html'
})
export class OutOfStockItemModalComponent implements OnInit {
  public context: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<OutOfStockItemModalContext>, private mobilePlansService: MobileCustomPlansService,
              private router: Router, private analyticsService: AnalyticsService, private appState: AppState,
              private checkoutService: CheckoutService, private location: PlatformLocation) {
    location.onPopState(() => { this.beforeDismiss();this.dialog.close();});
    this.context = data;
  }
  ngOnInit(): void {
  }
  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  closeDialog(): void {
    this.beforeDismiss();
    this.dialog.close();
  }
  public changePhone(): void {
    const params = {};
    params[PHONES_SHOP_ROUTE_URLS.PARAMS.CHANGE_PHONE] = true;
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${PHONES_SHOP_ROUTE_URLS.BASE}/${PHONES_SHOP_ROUTE_URLS.TYPE}`, params]);
    this.closeDialog();
  }
  public remove(): void {
    if (this.context.currentPlan.cartType === CART_TYPES.NEW_PLAN) {
      this.mobilePlansService.setPlanDevice(null);
      this.mobilePlansService.removePhonesFromCart();
      this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CHECK_PHONE}`]);
      this.closeDialog();
    } else if (this.context.currentPlan.cartType === CART_TYPES.GENERIC_CART) {
      this.mobilePlansService.clearUserCart();
      const removedItems = this.analyticsService.prepareItems(CART_TYPES.GENERIC_CART, {plan: null, sim: !this.context.currentPlan.activationCode ? this.context.currentPlan.planDevice : null, phone: !!this.context.currentPlan.phones && this.context.currentPlan.phones.length > 0 ? this.context.currentPlan.phones[0]: null, addons: null});
      this.analyticsService.trackRemoveFromCart(removedItems);
      this.appState.clearSessionStorage();
      this.checkoutService.shippingAddressSubject.next(undefined);
      sessionStorage.setItem('removeFromCart', 'true');
      this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.SUMMARY}`]);
      this.closeDialog();
    }
  }
}

