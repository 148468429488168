import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalHelperService } from 'src/services/modal-helper.service';
import { PlatformLocation } from '@angular/common';

export class MigrationConfirmationModalContext  {
  public phone: string;
  public imei: string;
  public customClass: string;
}
@Component({
  selector: 'app-migration-confirmation-modal',
  templateUrl: './migration-confirmation-modal.component.html'
})
export class MigrationConfirmationModalComponent {
  public context: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<MigrationConfirmationModalContext>, private modalHelper: ModalHelperService, private location: PlatformLocation) {
    location.onPopState(() => { this.beforeDismiss();this.dialog.close();});
    this.context = data;
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  closeDialog(): void {
    this.beforeDismiss();
    this.dialog.close();
  }

  confirm(response): void {
      this.beforeDismiss();
      this.dialog.close(response);

  }
}
