import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from '../widgets/widgets.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageAddressModalComponent } from './manage-address-modal/manage-address-modal.component';
import { ManagePaymentSpecificModalComponent } from './manage-payment-specific-modal/manage-payment-specific-modal.component';
import { ConfirmMessageModalComponent } from './confirm-message-modal/confirm-message-modal.component';
import { ConfirmPasswordModalComponent } from './confirm-password-modal/confirm-password-modal.component';
import { InformationMessageModalComponent } from './information-message-modal/information-message-modal.component';
import { AddActivatedNumberModalComponent } from './add-activated-number-modal/add-activated-number-modal.component';
import { InputModalComponent } from './input-modal/input-modal.component';
import { RoutingModalComponent } from './routing-modal/routing-modal.component';
import { PromotionTermsModalComponent } from './promotion-terms-modal/promotion-terms-modal.component';
import { EditCcModalComponent } from './edit-cc-modal/edit-cc-modal.component';
import { SelectPaymentModalComponent } from './select-payment-modal/select-payment-modal.component';
import { ShippingAddressModalComponent } from './shipping-address-modal/shipping-address-modal.component';
import { SimReplacementModalComponent } from './sim-replacement-modal/sim-replacement-modal.component';
import { WifiCallingModalComponent } from './wifi-calling-modal/wifi-calling-modal.component';
import { MigrationStepsComponent } from './migration-steps/migration-steps.component';
import { IsLteModalComponent } from './is-lte-modal/is-lte-modal.component';
import { DynamicModalsComponent } from './dynamic-modals/dynamic-modals.component';
import { ReferralPromotionComponent } from './referral-promotion-modal/referral-promotion.component';
import { FiveGModalComponent } from './five-gmodal/five-gmodal.component';
import { OutOfStockItemModalComponent } from './out-of-stock-item-modal/out-of-stock-item-modal.component';
import { AcpModalComponent } from './acp-modal/acp-modal.component';
import { PhoneNotImpactedModalComponent } from './phone-not-impacted-modal/phone-not-impacted-modal.component';
import { TrackingModalComponent } from './tracking-details-modal/tracking-details-modal.component';
import { MdnsListModalComponent } from './mdns-list-modal/mdns-list-modal.component';
import { CompatibilitySkipModalComponent } from './compatibility-skip-modal/compatibility-skip-modal.component';
import { eSimReplacementModalComponent } from './esim-replacement-modal/esim-replacement-modal.component';
import { SwiperModule } from 'swiper/angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { successModalComponent } from './success-modal/success-modal.component';
import { AlertSecurityModalComponent } from './alert-security-modal/alert-security-modal.component';
import { FuturePayProcessComponent } from './future-pay-process/future-pay-process.component';
import { CheckoutLoginComponent } from './checkout-login/checkout-login.component';
import { MigrationVerifiedFlowComponent } from './migration-verified-flow/migration-verified-flow.component';
import { MyTabModalComponent } from './my-tab-modal/my-tab-modal.component';
import { RouterModule } from '@angular/router';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { CanadianLocationComponent } from './canadian-location/canadian-location.component';
import { VerifyAddressModalComponent } from './verify-address-modal/verify-address-modal.component';
import { InstractionsFiveGModalComponent } from './instractions-five-g-modal/instractions-five-g-modal.component';
import { InstractionsFourGModalComponent } from './instractions-four-g-modal/instractions-four-g-modal.component';
import { InstractionsFiveGIos17ModalComponent } from './instractions-five-g-ios17-modal/instractions-five-g-ios17-modal.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { RecaptchaVerificationComponent } from './recaptcha-verification/recaptcha-verification.component';


@NgModule({
    declarations: [ManageAddressModalComponent, ManagePaymentSpecificModalComponent, ConfirmMessageModalComponent, ConfirmPasswordModalComponent,
        InformationMessageModalComponent,
        AddActivatedNumberModalComponent,
        InputModalComponent,
        RoutingModalComponent,
        PromotionTermsModalComponent,
        EditCcModalComponent,
        SelectPaymentModalComponent,
        ShippingAddressModalComponent,
        SimReplacementModalComponent,
        WifiCallingModalComponent,
        MigrationStepsComponent,
        IsLteModalComponent,
        ReferralPromotionComponent,
        DynamicModalsComponent,
        FiveGModalComponent,
        successModalComponent,
        OutOfStockItemModalComponent,
        AcpModalComponent,
        PhoneNotImpactedModalComponent,
        MdnsListModalComponent,
        TrackingModalComponent,
        eSimReplacementModalComponent,
        CompatibilitySkipModalComponent,
        AlertSecurityModalComponent,
        FuturePayProcessComponent,
        CheckoutLoginComponent,
        MigrationVerifiedFlowComponent,
        MyTabModalComponent,
        ErrorModalComponent,
        CanadianLocationComponent,
        VerifyAddressModalComponent,
        InstractionsFiveGModalComponent,
        InstractionsFourGModalComponent,
        InstractionsFiveGIos17ModalComponent,
        GoogleLoginComponent,
        RecaptchaVerificationComponent
    ],
    imports: [
        SwiperModule,
        MatAutocompleteModule,
        CommonModule, WidgetsModule, FormsModule, ReactiveFormsModule, RouterModule,
        GoogleSigninButtonModule
    ],
    exports: [ManageAddressModalComponent, ManagePaymentSpecificModalComponent, ConfirmMessageModalComponent, ConfirmPasswordModalComponent,
        InformationMessageModalComponent, AddActivatedNumberModalComponent, InputModalComponent,
        PromotionTermsModalComponent, EditCcModalComponent, SelectPaymentModalComponent, ShippingAddressModalComponent, SimReplacementModalComponent,
        WifiCallingModalComponent, MigrationStepsComponent, IsLteModalComponent, TrackingModalComponent,
        RoutingModalComponent, DynamicModalsComponent, ReferralPromotionComponent, FiveGModalComponent, successModalComponent, eSimReplacementModalComponent,
        OutOfStockItemModalComponent, AcpModalComponent, PhoneNotImpactedModalComponent, MdnsListModalComponent, CompatibilitySkipModalComponent, InstractionsFiveGModalComponent, InstractionsFourGModalComponent,
        InstractionsFiveGIos17ModalComponent,GoogleLoginComponent,RecaptchaVerificationComponent],
})
export class ModalsModule { }
