<div class="container model-container full {{context.customClass}}">
    <div class="modal-header">
        <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
    </div>
    <div class="modal-body">
        <div class="modal-content">
            <div class="swiper-container" (slideChange)="slideChanged()">
                    <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <h2 class="modal-heading">Good news!</h2>
                        <div class="flex-display">
                            <div class="desc">
                                <p>We now have MMS services available for iPhone users. Facetime over cellular data
                                    will also be available with the improved services on our preferred network.</p>
                                <p>Your phone must also be unlocked to work on the preferred network.  If you are not sure if your phone is locked to AT&T, please visit <a href="https://www.att.com/deviceunlock" target="_blank">https://www.att.com/deviceunlock/</a> to check your unlock status, or request an unlock.</p>
                            </div>
                            <div><img class="image" src="assets/img/Screen.svg"></div>
                        </div>
                        <div class="actions"><button class="button primary" (click)="showNextStep()">How it
                                works</button></div>
                    </div>
                    <div class="swiper-slide">
                        <h2 class="modal-heading">Get a Migration FREE SIM</h2>
                        <div class="flex-display">
                            <div class="desc">
                                <p>Confirm your IMEI and tell us the address where you use your phone the most. We will check to make sure everything lines up for the improved services,
                                    and then provide you with a migration <b>FREE SIM</b>.</p>
                            </div>
                            <div><img class="image" src="assets/img/Screen 2.svg"></div>
                        </div>
                        <div class="actions"><button class="button primary" data-cy="nextButton" (click)="showNextStep()" data-cy="next">Next</button></div>
                    </div>
                    <div class="swiper-slide">
                        <h2 class="modal-heading">Activate Migration SIM</h2>
                        <div class="flex-display">
                            <div class="desc">
                                <p>To activate your migration SIM, just login to your account and click on 'I received my SIM' from your account summary"</p>
                            </div>
                            <div><img class="image" src="assets/img/Screen 3.svg"></div>
                        </div>
                        <div class="actions"><button class="button primary" data-cy="nextButton" (click)="showNextStep()" data-cy="next">Next</button></div>
                    </div>
                    <div class="swiper-slide">
                        <h2 class="modal-heading">Your service is upgraded!</h2>
                        <div class="flex-display">
                            <div class="desc">
                                <p>Once your service has been migrated, you will receive an email from us, letting you know its
                                    time to set your new SIM in your phone and do a quick restart. </p>
                            </div>
                            <div><img class="image" src="assets/img/Screen 4.svg"></div>
                        </div>
                        <div class="actions"><button class="button primary" (click)="showNextStep()" data-cy="next">Next</button></div>
                    </div>
                    <div class="swiper-slide">
                        <h2 class="modal-heading">You’re good to go!</h2>
                        <div class="flex-display">
                            <div class="desc">
                                <p>When your phone powers up, your improved service will be ready for use. You will have
                                    the same plan,
                                    same phone, same phone number, with a new SIM that gives you improved features.</p>
                                <p><b>Ready to start your service migration and request your Free SIM?</b></p>
                            </div>
                            <div><img class="image" src="assets/img/Screen 5.svg"></div>
                        </div>
                        <div class="actions"><button class="button primary last" (click)="startMigration(true)">Yes, start service migration</button>
                            <a class="not-interested" (click)="startMigration(false)">Not interested</a>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</div>