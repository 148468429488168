import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlatformLocation } from '@angular/common';

export class PromotionTermsModalContext {
  public SUPPORT_ROUTE_URLS: any;
  public title?: string;
  public hasCloseLink?: boolean;
  public message1?: string;
  public message2?: string;
  public customClass?: string;
}

@Component({
  selector: 'app-promotion-terms-modal',
  templateUrl: './promotion-terms-modal.component.html'
})
export class PromotionTermsModalComponent {
  public context: any;
  public isAgreed: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<PromotionTermsModalContext>, private location: PlatformLocation) {
    location.onPopState(() => { this.beforeDismiss();this.dialog.close();});
    this.context = data;
  }

  public goToTerms(): void {
      window.open(this.context.SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS, '_blank');
  }

  public proceed(): void {
    this.beforeDismiss();
    this.dialog.close(this.isAgreed);
  }
  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  public closeDialog(): void {
    this.beforeDismiss();
    this.dialog.close(false);
  }
  cancel(): void {
    this.beforeDismiss();
    this.dialog.close();
  }
}

